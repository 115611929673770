<template>
    <div class="def__height">

        <!-- <div class="page__name">
            <i class="icon-chart-bars"></i><span>СООБЩЕНИЯ</span>
        </div> -->

        <!-- <div class="same__config">
            <div class="config__title mob">
                Сообщения
            </div>
        </div> -->

        <div class="messages__wrapper" v-if="matches.length>0">
<!--            <div class="vuebar-element sel__c">-->
<!--        <div class="select__chat">-->
<!--            <div-->
<!--                v-for="(match, index) in matches"-->

<!--                v-bind:data-id="match.room_id"-->
<!--                class="user__chat "-->
<!--                v-bind:class="{active:match.user.id==clicked_id}"-->
<!--                v-on:click="Id_clicked(index,match.id)"-->
<!--            >-->

<!--                <div class="some__chat__img"   v-bind:style="{ 'background-image': 'url(' + match.user.avatar + ')' }">-->

<!--                </div>-->

<!--                <div class="new_msg_count" v-if="match.msg_count">{{match.msg_count}}</div>-->

<!--            <div class="some__chat__name">-->
<!--                {{match.user.name}}-->
<!--            </div>-->




<!--            </div>-->
<!--        </div>-->

            <div class="selected__chat" v-bind:class="{active:clicked_id!=''}">
                <div class="user__profile__mob mob">
                    <!-- <div class="to__all__chats" v-on:click="clicked_id = ''" v-bind:class="{hidden:active_info_mob}"> -->
                    <div class="to__all__chats">
                        <router-link to="/matches">
                            <i class="icon-chevron-left"></i>
                        </router-link>
                    </div>
                    <div class="some__chat__img">
                      <img
                          class="bg-img"
                          style="width: 100% !important; height: 100% !important;"
                          :src="userprofile.image_url || '/assets/images/project/users/placeholder.jpg'"
                          @error="handleImageError"
                          alt="background"
                      />
                        <!--<img v-bind:src="userprofile.image_url" v-bind:alt="userprofile.name">-->
                    </div>
                    <div class="mob__profile__name">
                        {{userprofile.name}}
                    </div>
                    <!-- <div class="open__mob__profile" v-on:click="active_info_mob = true">
                        <span>&bull;&bull;&bull;</span>
                    </div> -->
                </div>

                <div class="messages__area">
                    <div class="just__text__messages">

<!--                        <div class="no_chat_selected">Выберите собеседника</div>-->
                        <div class="chat_message"
                             v-for="(item, index) in messages"



                        >


                            <div class=" " v-bind:class="{ message__myself: item.user_from==$store.getters.MAINUSER.id, message__tome: item.user_from==clicked_id }" style="color:#000">
                                <div class="time__message">{{moment(item.created_at).format('DD-MM-YYYY HH:mm:ss')}}</div>
                                <div class="text__message message__receive">{{item.body}}</div>


                            </div>


                        </div>


                    </div>
                </div>
                <div class="sent__area" v-if="clicked_id!=''">
                    <textarea placeholder="Введите сообщение"  v-model="textMessage"></textarea>
                    <div class="send__mail__btn" v-on:click="sendMessage()">
                        <img src="@/assets/images/project/plane-icon.png" alt="send">
                    </div>
                </div>
            </div>

            <!-- <div class="vuebar-element selected__user" v-if="clicked_id!=''" v-bind:class="{active: active_info_mob}">
                <div class="chat__talker">
                    <div class="talker__wrapper">

                        <div class="talker__pic" v-bind:style="{ 'background-image': 'url(' + userprofile.avatar + ')' }">
                            <img v-bind:src="userprofile.image_url" v-bind:alt="userprofile.name">
                        </div>
                        <div class="talker__name">
                            <span class="current__name">{{userprofile.name}}</span>
                            <span class="talker__inf">
                                 <span class="talker__gender" v-if="userprofile.gender == 1">
            <img src="/assets/images/project/gender/male.png" alt="gender">
          </span>
                    <span class="talker__gender" v-if="userprofile.gender == 0">
            <img src="/assets/images/project/gender/female.png" alt="gender">
          </span>

                  <span class="talker__age"> {{userprofile.age}}</span>&nbsp;
                                <span class="talker__city" v-if="city">
                ,&nbsp;{{city}}
               </span>

                </span>
                        </div>
                        <div class="talker__aboutmyself" v-if="userprofile.about">
                            <div class="aboutself__header">
                                Обо мне
                            </div>
                            <div class="aboutself__body">
                                {{userprofile.about}}
                            </div>
                        </div>

                        <div class="talker__interest">
                            <div class="aboutself__header">
                                Интересы
                            </div>
                            <div class="aboutself__interests">
                                <div class="interest__item"
                                     v-for="(item, index) in InterestsUser"> <span>{{item.name}}</span></div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="interest__closer mob" v-on:click="active_info_mob = false">
                    <i class="icon-rank"></i>
                </div>
            </div> -->
            </div>


        <div class="noMatches active" v-else><span>Хотите с кем-нибудь пообщаться? <br>Образуйте свою первую пару и отправьте ей сообщение</span></div>
        </div>
</template>

<script>
import axios from 'axios';
import moment from "moment";

    export default {
      computed: {
        moment() {
          return moment
        },
        room_id(){
          return this.$store.state.room_id
        } ,
        socket(){
          return this.$store.state.socket
        },
        main_user(){
          return this.$store.state.mainuser
        },
        messages(){
          return this.$store.state.messages
        }
      },
        props: ['id'],
       data(){
           return{
               textMessage:'',
               matches:[],
               clicked_id:'',
               active_info_mob:false,
               userprofile:[],
               InterestsUser:[],
               city:'',
           }
       },
        // created(){this.token=$("meta[name='csrf-token']").attr('content');console.log(this.token);},
        mounted(){
          this.socket.on("message:new", (collection) => {
            this.$store.dispatch("ADD_MESSAGE",collection)
          });
            const that=this;

            console.log(this.$route.params.id);
            if (this.$route.params.id) {
              this.clicked_id = this.$route.params.id;

              console.log(this.clicked_id);

              axios.post('/api/user/getById', {id:this.$route.params.id})

                  .then((response) => {

                    // console.log(response);
                    this.userprofile = response.data.result
                    axios.post('/api/user/hobbies/get', {id:this.$route.params.id}).then((response) => {
                      this.InterestsUser = response.data.result;
                      // console.log(this.InterestsUser)
                      axios.post('/api/user/matches/get', {id:this.$route.params.id}).then((response) => {
that.matches=response.data.result
                        const found_match=response.data.result.find((item) => ((item.user1 === this.main_user.id && item.user2 == this.$route.params.id)||(item.user2 === this.main_user.id && item.user1 == this.$route.params.id)))
                        that.$store.commit('SET_MAINUSER_ROOM',found_match.room_id)

                      axios.post('/api/user/chat', {room_id:this.main_user.room_id})

                          .then((response) => {

                            // response.data.image_url = "\"" + response.data.image_url + "\"";
                            console.log(response);
                            console.log(that.messages)
                            response.data.result.forEach((elem)=>{
                              that.$store.dispatch('ADD_MESSAGE',{
                                body: elem.message,
                                user_from: elem.user_from,
                                created_at: elem.created_at
                              })
                              // that.messages.push();
                            })

                            // this.userprofile = response.data
                            // console.log(this.user);
                          })
                          })
                          .catch(error => {
                            console.log('ошибка ' + error);
                          });
                    })
                  })
                  .catch(error => {
                    console.log('ошибка ' + error);
                  });

              ;

            }


        },

        methods:{

          handleImageError(event) {
            event.target.src = '/assets/images/project/users/placeholder_user.svg'
          },
            chat_messages(){
                document.querySelector('.send__mail__btn').onclick = function() {console.log('click') }
                // // if(document.querySelector('.messages__wrapper')) {
                //     const chats = document.querySelectorAll('.user__chat')
                //
                //     document.querySelector('.open__mob__profile').onclick = function() {
                //         document.querySelector('.selected__user').classList.toggle('active')
                //     }
                //
                //     document.querySelector('.interest__closer').onclick = function() {
                //         document.querySelector('.selected__user').classList.toggle('active')
                //     }
                //
                //     for(let i = 0; i < chats.length; i++) {
                //         chats[i].onclick = function() {
                //             document.querySelector('.selected__chat').classList.toggle('active')
                //             document.querySelector('.sel__c ').classList.toggle('active')
                //
                //         }
                //     }
                //
                //     document.querySelector('.to__all__chats').onclick = () => {
                //         document.querySelector('.selected__chat').classList.toggle('active')
                //         document.querySelector('.sel__c ').classList.toggle('active')
                //     }
                // // }
            },


//            Id_clicked(index,base_id){
//                let that=this
//                // console.log(that)
//                this.clicked_id = base_id;
//                this.messages.splice(0,this.messages.length);
// return
//
//                // console.log(this.clicked_id)
//                axios.get(r("api.user.ById",[`${base_id}`] ))
//
//                    .then((response) => {
//                        // console.log(response);
//                response.data.image_url = "\""+ response.data.image_url+"\"";
//                        this.userprofile = response.data
//                        let hobby = response.data.hobbies;
//                        this.InterestsUser=hobby.split(',');
//                        // console.log(this.InterestsUser)
//                        if (response.data.latitude==0||response.data.longitude==0) this.city='Алматы'
// else {
//                            axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' + response.data.latitude + '&lon=' + response.data.longitude)
//                                .then(resp => {
//                                    // console.log(resp);
//                                    this.city = resp.data.address.city
//
//
//                                })
//                                .catch(error => {
//                                    console.log('ошибка ' + error);
//                                });
//                        }
//                    })
//                    .catch(error => {
//                        console.log('ошибка ' + error);
//                    });
//                axios.post(r("api.user.messages.update"), {
//                    id: this.clicked_id,
//
//                })
//                    .then((response) => {
//                            console.log('GET_ALL_NEW_MESSAGES');
//                            console.log(response)
//                            that.$store.dispatch('GET_ALL_NEW_MESSAGES')
//                        })
//
//                ;
//                axios.get(r("api.user.chat",[`${base_id}`] ))
//
//                    .then((response) => {
//                        // console.log(response);
//                response.data.image_url = "\""+ response.data.image_url+"\"";
//                        // console.log(that.messages)
//                        let index;
//                        for (index = 0; index < response.data.length; ++index) {
//                            that.messages.push({body:response.data[index].message, user_from:response.data[index].user_from, user_for:response.data[index].user_for, created_at:response.data[index].created_at});
//
//                        }
//                        // this.userprofile = response.data
//                        // console.log(this.user);
//                    })
//                    .catch(error => {
//                        console.log('ошибка ' + error);
//                    });
//                console.log(this.matches)
//                for (index = 0; index < this.matches.length; ++index) {
//                    console.log(this.matches[index])
//                 if (this.matches[index].id==this.clicked_id) {
//                     this.matches[index].msg_count=0;console.log('match_found')
//                     }
//
//                     let vh = window.innerHeight * 0.01;
//                     console.log(vh)
//                     console.log(document.querySelector('.selected__chat'))
//                     document.querySelector('.selected__chat').style.setProperty('—vh', vh + 'px');
//
//                     window.addEventListener('resize', () => {
//                         let h = window.innerHeight * 0.01;
//                         console.log(h)
//                         document.querySelector('.selected__chat').style.setProperty('--vh', h + 'px');
//                     });
//                }
//            },
            sendMessage(){
             
                Date.prototype.format = function(format = 'yyyy-mm-dd') {
                    const replaces = {
                        yyyy: this.getFullYear(),
                        mm: ('0'+(this.getMonth() + 1)).slice(-2),
                        dd: ('0'+this.getDate()).slice(-2),
                        hh: ('0'+this.getHours()).slice(-2),
                        MM: ('0'+this.getMinutes()).slice(-2),
                        ss: ('0'+this.getSeconds()).slice(-2)
                    };
                    let result = format;
                    for(const replace in replaces){
                        result = result.replace(replace,replaces[replace]);
                    }
                    return result;
                };


if (this.textMessage!= '') {
  axios.post('/api/messages', {
        message_body: this.textMessage,
    room_id: this.main_user.room_id,
        user_for: this.clicked_id,
        id_from: this.$store.getters.MAINUSER.id,
        created_at: (new Date()).format('yyyy-mm-dd hh:MM:ss')
    });

  this.socket.emit('message:create', {  message_body: this.textMessage,
    room_id: this.main_user.room_id,
    from: this.$store.getters.MAINUSER.id,
    created_at: (new Date()).format('yyyy-mm-dd hh:MM:ss')});

    this.textMessage = '';
}
            },

        }
    };
</script>
