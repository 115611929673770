export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_BLOCKED = 'AUTH_BLOCKED'
export const AUTH_ERROR_LOGIN = 'AUTH_ERROR_LOGIN'
export const AUTH_ERROR_PASSWORD = 'AUTH_ERROR_PASSWORD'
export const AUTH_UNREGISTERED = 'AUTH_UNREGISTERED'
export const AUTH_CLEAR = 'AUTH_CLEAR'


