import { USER_REQUEST, USER_ERROR, USER_SUCCESS, SET_CITY} from '../actions/user'
// import apiCall from '@/js/utils/api'
// import Vue from 'vue'
import { AUTH_LOGOUT } from '../actions/auth'
import axios from 'axios';
// import r from '@/js/route';

const state = { status: '', profile: {} , city:''}

const getters = {
  getProfile: state => state.profile,
  city: state => state.city,
  isProfileLoaded: state => !!state.profile.name,
}

const actions = {
  [USER_REQUEST]: ({commit, dispatch}) => {
    commit(USER_REQUEST)
      // console.log( r("api.user.show"));
      // axios.get(r("api.user.show"))
      // .then(resp => {
      //   // console.log( resp);
      //   // console.log('широта - '+resp.data.latitude);
      //   // console.log('долгота - '+resp.data.longitude);
      //
      //   axios.get('https://nominatim.openstreetmap.org/reverse?format=json&lat='+resp.data.latitude+'&lon='+resp.data.longitude)
      //       .then(resp => {
      //         // console.log( resp);
      //         // city=resp.data.address.city
      //         // console.log(city);
      //         commit(SET_CITY, resp)
      //       })
      //
      //   commit(USER_SUCCESS, resp)
      // })
      //
      //
      // .catch(resp => {
      //   commit(USER_ERROR)
      //   // if resp is unauthorized, logout, to
      //   dispatch(AUTH_LOGOUT)
      // })
  },
}


const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = 200
    state.profile=resp.data
  },
  // [SET_CITY]: (state, resp) => {
  //   state.city = resp.data.address.city
  //   // Vue.set(state, 'city', resp.data.address.city)
  //   // console.log(state.city+ ' city '+ resp.data.address.city);
  // },
  [USER_ERROR]: (state) => {
    state.status = 'error'
  },
  [AUTH_LOGOUT]: (state) => {
    state.profile = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}
