/**
* Created by vouill on 11/13/17.
*/

<template>
  <div>
<!--    <loading v-if="loading"/>-->
    <div v-if="isAuthenticated">
        <Users/>

    </div>
    <div v-if="!isAuthenticated && authStatus !== 'loading'">
      
      <Auth/>
    </div>
  </div>
</template>



<script>

  import { mapGetters } from 'vuex'
  import Auth from '../Auth.vue'
  import Users from '../Users/index.vue'
  import {io} from "socket.io-client";

  export default {
    components: {
      Auth,Users
    },
    name: 'home',
    created: function () {
      if (!this.$store.state.debug) {
        let that = this;

        if (!that.socket) {
          that.$store.commit('setsocket', io());
        }
      }
    },
    computed: {
      ...mapGetters(['isAuthenticated', 'authStatus']),
      loading: function () {
        console.log(this.isAuthenticated)
        return this.authStatus === 'loading' && !this.isAuthenticated
      },

        socket: function () {
          return this.$store.state.socket;
        },
        socketinit: function () {
          return this.$store.state.socketinit;
        },
    },


  }
</script>
