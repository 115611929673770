import {createMemoryHistory, createRouter} from 'vue-router'

import Auth from '../components/Auth.vue'

import Settings from '../components/settings/index.vue'
import Account from '../components/account/index.vue'
import Users from '../components/Users/index.vue'
import UserProfile from '../components/UserProfile/index.vue'
import Matches from '../components/matches/index.vue'
import Chat from '../components/chat/index.vue'
import App from '../components/views/App.vue'
//
import Home from '../components/home/index.vue'

import MainStore from "../stores";

const store = MainStore.store;

const ifNotAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {

        return next('/home')
    } else {
        console.log(store.getters.MAINUSER === null)
        return next();

    }

}


const ifAuthenticated = (to, from, next) => {

    console.log('ifAuthenticated')
    console.log(store.getters.isAuthenticated)
    console.log(store.getters.SOCKET === null)
    console.log(store.getters.MAINUSER)
    if (store.getters.isAuthenticated) {
        if (store.getters.MAINUSER === null) {

            store.dispatch('GET_MAINUSER').then(() => {
                console.log('GET_MAINUSER then')
                    if (store.getters.SOCKET === null) {

                        store.dispatch('SET_SOCKET')
                            .then(() => {
                                store.getters.SOCKET.emit('join', store.getters.MAINUSER, data => {
                                    if (typeof data === 'string') {
                                    }
                                });
                                return next();
                            });
                    } else {
                        return next();
                    }

            }).catch((e)=>{
                console.log('GET_MAINUSER e')
                console.log(e)
                // if(e==='no user-token'){store.dispatch('AUTH_LOGOUT').then(() => {
                //     next('/login')
                // })}
                store.dispatch('GET_MAINUSER_BY_ID').then(() => {
                    if (store.getters.SOCKET === null) {
                        store.dispatch('SET_SOCKET')
                            .then(() => {
                                store.getters.SOCKET.emit('join', store.getters.MAINUSER, data => {
                                    if (typeof data === 'string') {
                                    }
                                });


                                return next();
                            });
                    } else {
                        return next();
                    }
            }).catch((e)=>{
                    console.log(e)
                    store.dispatch('AUTH_LOGOUT').then(() => {
                    next('/login')
            })
            })
            })
            } else {
                return next();
            }

    } else {
        next('/login')
    }
}

let routes =
    [
        {
            path: '/',
            redirect: {name: 'login'},
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/login',
            name: 'login',
            component: Auth,
            beforeEnter: ifNotAuthenticated,
        },

        //
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/home',
            name: 'home',
            component: Home,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/search',
            name: 'search',
            component: Users,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/account',
            name: 'account',
            component: Account,
            beforeEnter: ifAuthenticated,
        }, {
            path: '/matches',
            name: 'matches',
            component: Matches,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/profile/:id',
            name: 'UserProfile',
            component: UserProfile,
            props: true
        },
        {
            path: '/chat/:id',
            name: 'chat',
            component: Chat
        },

        // {
        //     path: '/tournaments/',
        //     name: 'tournaments',
        //     component: Tournaments,
        //   beforeEnter: ifAuthenticated,
        //   },
        // {
        //     path: '/matches/',
        //     name: 'matches',
        //     component: Matches,
        //
        //   },
        // {
        //     path: '/sources/',
        //     name: 'sources',
        //     component: Sources,
        //
        //   },
        // {
        //     path: '/rating/',
        //     name: 'rating',
        //     component: Rating,
        //
        //   },
        // {
        //     path: '/teams/',
        //     name: 'teams',
        //     component: Teams,
        //
        //   },
        // {
        //   path: '/partitions/',
        //   name: 'partitions',
        //   component: Partitions,
        //
        // },
        // {
        //   path: '/places/',
        //   name: 'places',
        //   component: Places,
        //
        // },
        // {
        //   path: '/news/',
        //   name: 'news',
        //   component: News,
        //
        // },
    ];
// history: createMemoryHistory(rootDir),
const router = createRouter({
    history: createMemoryHistory(),
    routes
})

// router.beforeEach(async (to, from) => {
//   // canUserAccess() returns `true` or `false`
//   let that=this
//   const canAccess = await ifAuthenticated()
//   if (!canAccess) return '/login'
// })
// router.beforeEach((to, from, next) => {
//   let that=this
//   that.isAuthenticated();
//   if (to.name !== 'Login' && !isAuthenticated) next({ name: 'Login' })
//   else next()
// })
export default router
