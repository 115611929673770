<template>
  <div>
    <!-- <div class="page__name">
      <i class="icon-briefcase"></i><span>МОЙ ПРОФИЛЬ</span>
    </div> -->

    <!--    <div class="same__config">-->
    <!--      <div class="config__title">-->
    <!--        Мой профиль-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="profile__settings" v-if="this.$store.getters.MAINUSER">
      <div class="profile__settings__body">
        <div class="prof__card">
          <div class="card__photo"> <!-- v-bind:style="{ 'background-image': 'url(' + MainUser.avatar + ')' }" -->
            <img
                v-bind:src="MainUser.avatar || '/assets/images/project/users/placeholder_user.svg'"
                alt="my-photo"
                @error="handleImageError"
            >
          </div>

          <div class="card__name">
            <span>{{ MainUser.name }}, </span>
            <span class="user__age">
                {{ MainUser.age }} {{ addAgeWord(MainUser.age) }}
              </span>
          </div>

          <div class="card__row">
            <router-link to="/settings">
              <div class="config__btn" v-on:click="EditSettings">
                <img src="@/assets/images/project/settings.svg" alt="icon edit">
                <span>Настройки</span>
              </div>
            </router-link>

            <div class="card__gender">
             <span class="found__gender" v-if="MainUser.gender == 'Мужчина'">
            <img src="/images/project/gender/male_black.svg" alt="gender">
          </span>
              <span class="gender__img" v-if="MainUser.gender == 'Женщина'">
            <img src="/images/project/gender/female_black.svg" alt="gender">
          </span>
            </div>

            <div class="config__btn" v-on:click="EditSettings">
              <img src="@/assets/images/project/edit-icon.png" alt="icon edit">Редактировать
            </div>
          </div>
        </div>

        <div class="profile__tabs">
      <span
          :class="{'active-tab': activeTab === 'gallery'}"
          @click="activeTab = 'gallery'"
      >Галерея</span>

          <span
              :class="{'active-tab': activeTab === 'info'}"
              @click="activeTab = 'info'"
          >Инфо</span>
        </div>

        <div v-if="activeTab === 'gallery'">
          <Uploader
              server="/api/upload"
              :media="this.$store.getters.MAINUSER.gallery"
              path="/images/users/"
              :maxFilesize=15
              @add="addMedia"
              @remove="removeMedia"
              class="user__photos"
          />
        </div>

        <div v-if="activeTab === 'info'" class="about__profile">

          <div class="profile__me" v-if="MainUser.about">
            <p class=about__head>Обо мне</p>

            <div class="about__me">
              {{ MainUser.about }}
            </div>
          </div>
          <div class="profile__me" v-if="MainUser.work">
            <p class=about__head>Чем занимаюсь</p>

            <div class="about__me">
              {{ MainUser.work }}
            </div>
          </div>

          <div class="profile__hobbies">
            <p class=about__head>Интересы</p>

            <div class="about__content">
              <div class="hobby__item"
                   v-for="(item, index) in MainUserHobbies">
                <span>{{ item.name }}</span>
              </div>

            </div>
          </div>
        </div>

        <div class="setup__pop" v-bind:class="{active: isPhotoActive}">
          <div class="preloader_img_loading popup_loading" v-bind:class="{active: isLoading}">
            <img src="/assets/img/preloader.svg">
          </div>
          <div class="pop__close" v-on:click="isPhotoActive = false">
            <i class="icon-cross"></i>
          </div>


          <div style="margin-top:15%">
            <div class="change_photo" v-if="change_photo">Выберите другую фотографию</div>
            <Uploader
                server="/api/upload"
                @change="changeMedia"
                :media="avatar.added"
                :max=1
                :maxFilesize=15
                class="Uploader_avatar"
            />
          </div>
        </div>

        <div class="setup__pop avatar" v-bind:class="{active: isAvatarActive}">
          <div class="preloader_img_loading popup_loading" v-bind:class="{active: isLoading}">
            <img src="/assets/img/preloader.svg">
          </div>
          <div class="pop__close" v-on:click="isAvatarActive = false">
            <i class="icon-cross"></i>
          </div>


          <div style="margin-top:15%">
            <div class="change_photo" v-if="change_photo">Выберите другую фотографию</div>
            <Uploader
                server="/api/upload"
                @change="changeMedia"
                :media="avatar.added"
                :max=1
                :maxFilesize=15
                class="Uploader_avatar"
            />
          </div>
        </div>

        <div class="setup__pop" v-bind:class="{active: isActive}">

          <div class="pop__header mob">
            Редактирование
            <div class="pop__close" v-on:click="closeEdit">
              <i class="icon-cross"></i>
            </div>
          </div>

          <div class="pop__header desk">
            Редактирование профиля
            <div class="pop__close" v-on:click="closeEdit">
              <i class="icon-cross"></i>
            </div>
          </div>

          <div class="pop__photo">

            <div class="card__photo" v-if="MainUser.avatar">
              <img
                  v-bind:src="MainUser.avatar || '/assets/images/project/users/placeholder_user.svg'"
                  alt="my-photo"
                  @error="handleImageError"
              >
            </div>
            <div class="card__photo" v-else>
              <img v-bind:src="'/assets/images/project/users/placeholder_user.svg'" alt="my-photo">
            </div>

            <div class="photo__changer" v-on:click="isAvatarActive = true">
              <i class="icon-pencil"></i>
            </div>

          </div>

          <div class="pop__form">
            <form v-on:submit.prevent="send()">

              <div class="name_birth__row">
                <div class="pop__name">
                  <label for="pop__name">Имя<span class="red_star">*</span></label>
                  <input class="input__name" type="text" id="pop__name" placeholder="Введите имя"
                         v-model="name" max="50" maxlength="50">
                  <div class="name__err">Поле не заполнено</div>
                </div>

                <div class="pop__age">
                  <label for="pop__age">Дата рождения <span class="red_star">*</span></label>
                  <input type="date" name="pop__age" id="pop__age" v-model="selected_age">
                </div>
              </div>

              <div class="select__gender">
                <label for="pop__name">Пол <span class="red_star">*</span></label>

                <div class="gender__set">
                  <!-- Кнопка "Мужчина" -->
                  <button
                      class="gender__btn"
                      :class="{ active: gender === 0 }"
                      @click.prevent="changeGender()"
                  >
                    <span class="icon">Мужчина</span>
                  </button>

                  <!-- Кнопка "Женщина" -->
                  <button
                      class="gender__btn"
                      :class="{ active: gender === 1 }"
                      @click.prevent="changeGender()"
                  >
                    <span class="icon">Женщина</span>
                  </button>
                </div>
              </div>

              <div class="area__block">
                <div class="text__area">
                  <label for="pop__area" class="l-top">Обо мне</label>
                  <textarea name="pop__area" id="pop__area" placeholder="до 250 символов"
                            v-model="message" max="250" maxlength="250"></textarea>
                </div>

                <div class="text__area">
                  <label for="pop__area" class="l-top">Чем занимаюсь</label>
                  <textarea name="pop__area" id="pop__area" placeholder="до 250 символов"
                            v-model="work" max="250" maxlength="250"></textarea>
                </div>
              </div>


              <div class="pop__hobbies" id="pop__hobbies">
                <label for="pop__hobbies" class="l-top">Интересы</label>
                <div class="all__hobb">
                  <div class="hobby__item" v-bind:class="{active: item.active}"
                       v-for="(item, index) in InterestsAll"
                       @click="addRemouveClassInterest(index)"
                       v-bind:index="item.id"

                  >{{ item.name }}<i class="icon-cross"></i></div>

                </div>
              </div>
              <button class="pop__sent">Сохранить</button>
              <div class="err_required restore__pop" :class="{active:err_required_block}" v-if='err_required_block'>
                <div class="close__restore"
                     v-on:click="err_required_block=false;err_too_young=false;err_required=false;">
                  <i class="icon-cross"></i></div>
                <div class="text__restore" v-if="err_required">
                  Обязательные поля не заполнены
                </div>
                <div class="text__restore" v-if="err_too_young">
                  Cервис доступен для пользователей старше 18ти лет
                </div>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>


<script>
import axios from 'axios';
import moment from "moment";
import Uploader from "vue-media-upload";

// import 'cropperjs/dist/cropper.css';

// import '@/js/assets/js/profile.js';

// import DemoFilePond from '@/js/components/Doka/DemoFilePond.vue';
// import DemoFilePond2 from '@/js/components/Doka/DemoFilePond2.vue';

export default {

  data() {
    return {
      media: {
        saved: [],
        added: [],
        removed: []
      },
      avatar: {
        saved: [],
        added: [],
        removed: []
      },
      activeTab: 'gallery',
      visible: false,
      Carus: [],
      Gal: [],
      GalPopup: [],
      UplPlace: [],
      InterestsAllVars: [],
      InterestsAll: [],
      InterestsUser: [],
      isActive: false,
      isPhotoActive: false,
      isAvatarActive: false,
      isLoading: false,
      name: '',
      gender: null,
      message: '',
      work: '',
      selected_age: '',
      male: 0,
      female: 0,
      activeInterest: false,
      err_required_block: false,
      err_required: false,
      err_too_young: false,
      change_photo: false,

    }

  },
  name: 'account',
  components: {Uploader},

  computed: {

    MainUser() {
      // console.log('MAINUSER')
      // console.log(this.$store.getters.MAINUSER);

      this.name = this.$store.getters.MAINUSER.name;
      this.message = this.$store.getters.MAINUSER.about;
      this.work = this.$store.getters.MAINUSER.work;
      this.selected_age = this.$store.getters.MAINUSER.dob;
      this.gender = this.$store.getters.MAINUSER.gender;

      this.InterestsUser = this.$store.getters.MAINUSER.hobbies;
      if (this.$store.getters.MAINUSER.gallery && this.$store.getters.MAINUSER.gallery.length > 0) {
        this.$store.getters.MAINUSER.gallery.forEach((elem) => {
          elem.name = elem.url.slice(1)
        })
      }
      if (this.$store.getters.MAINUSER.name == '' || this.$store.getters.MAINUSER.name == null || this.$store.getters.MAINUSER.dob == null || this.$store.getters.MAINUSER.gender == null) {
        // console.log('new_user')
        if (this.$store.getters.MAINUSER.image_url == null) this.isAvatarActive = true;
        this.isActive = true;
      }
      return this.$store.getters.MAINUSER;

    },
    MainUserHobbies() {
      return this.$store.getters.MAINUSER_HOBBIES;
    },

  },
  methods: {
    handleImageError(event) {
      event.target.src = '/assets/images/project/users/placeholder_user.svg'
    },
    addAgeWord(age) {
      if (age % 10 === 1 && age % 100 !== 11) {
        return 'год';
      } else if ([2, 3, 4].includes(age % 10) && ![12, 13, 14].includes(age % 100)) {
        return 'года';
      } else {
        return 'лет';
      }
    },
    changeGender() {
      if (this.gender === 1) {
        this.gender = 0
      } else {
        this.gender = 1
      }
    },
    addMedia(addedImage, addedMedia) {
      axios.post('/api/user/gallery/insert', {user_id: this.MainUser.id, name: addedImage.name}).then(async () => {
        await this.$store.dispatch('GET_MAINUSER_BY_ID', this.$store.getters.MAINUSER.id)
        this.media.added = addedImage.name
      })
    },
    removeMedia(removedImage, removedMedia) {
      axios.post('/api/user/gallery/delete', {user_id: this.MainUser.id, name: removedImage.name}).then(async () => {
        await this.$store.dispatch('GET_MAINUSER_BY_ID', this.$store.getters.MAINUSER.id)
        this.media.removed = removedMedia
      })
    },
    changeMedia(media) {
      console.log(media)
      if (media.length > 0) {
        let a = document.querySelectorAll('.Uploader_avatar .mu-plusbox-container')
        a.forEach((elem) => {
          elem.classList.add('hidden')
        })
        axios.post('/api/user/avatar/update', {user_id: this.MainUser.id, name: media[0].name}).then(() => {
          axios.post('/api/upload/delete', {name: this.MainUser.avatar}).then(() => {

            this.$store.dispatch('GET_MAINUSER_BY_ID', this.$store.getters.MAINUSER.id)
            this.isAvatarActive = false
          })
              .catch((e) => {
                console.log(e)
              })
        }).catch((e) => {
          console.log(e)
        })
      } else {
        let a = document.querySelectorAll('.Uploader_avatar .mu-plusbox-container')
        a.forEach((elem) => {
          elem.classList.remove('hidden')
        })
      }

      this.avatar = media
    },

    closeEdit() {
      if (this.name == null || this.selected_age == null) {
        this.err_required = true;
        this.err_required_block = true;
        return;
      }

      if (this.isActive) {
        this.isActive = false;
      }
    },

    addRemouveClass:
        function (index) {
          // console.log(this.Gal[index]);
          this.Gal[index].active = !this.Gal[index].active;
        },
    addRemouve:
        function (index, base_id) {
          // console.log(index);
          // console.log(base_id);
          this.Gal.splice(index, 1)
          this.GalPopup.splice(index + 1, 1)
          this.UplPlace.push('');
          // console.log(this.Gal);
          axios.post(r("api.files.destroy", [`${base_id}`]))

              .then(function (response) {

                console.log(response.data);

              })
              .catch(error => {
                console.log(error)
              })

        },
    addRemouveClassInterest: function (index) {
      // console.log(this.InterestsAllVars[index]);
      this.InterestsAll[index].active = !this.InterestsAll[index].active;
    },
    showImg(index) {

      this.index = index + 1

      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    EditSettings() {
      this.isActive = true
    },
    change(action) {
      const {editor} = this.$refs;

      switch (action) {
        case 'crop':
          editor.crop();
          break;

        case 'clear':
          editor.clear();
          break;

        case 'restore':
          editor.restore();
          break;

        case 'remove':
          editor.reset();
          break;

        default:
      }
    },
    getUserGall() {

      // console.log(this.UserMain)
      //
      if (this.$store.getters.MAINUSER) this.GalPopup.push(this.$store.getters.MAINUSER.image_url);
      axios.get(r("api.user.galleryShow"))

          .then((response) => {
            // console.log(this.$store.getters.MAINUSER.image_url)
            let index;
            for (index = 0; index < response.data.length; ++index) {
              this.Gal.push({
                active: false,
                id: response.data[index].id,
                img: '/storage/' + response.data[index].user_id + '/' + response.data[index].type + '/' + response.data[index].name + '.' + response.data[index].extension + ""
              });
              this.GalPopup.push('/storage/' + response.data[index].user_id + '/' + response.data[index].type + '/' + response.data[index].name + '.' + response.data[index].extension + "");
            }
            let index2;
            let leng = 11 - response.data.length;
            for (index2 = 0; index2 < leng; ++index2) {

              this.UplPlace.push('');
            }
            // console.log(response.data);
            // console.log(response.data.length);
            // console.log(leng);
            // console.log(this.UplPlace)

          })
          .catch(error => {
            console.log('ошибка ' + error);
          });

      ;

    },
    NewGallFile(resp) {
      // console.log(resp)
      this.Gal.push({active: false, id: resp.id, img: resp.img});
      this.GalPopup.push(resp.img);
      this.UplPlace.shift();
      this.isPhotoActive = false
      this.isLoading = false
    },
    LoadingImg() {
      this.isLoading = true;
      this.change_photo = false;
    },
    ChangePhoto() {
      this.isLoading = false;
      this.change_photo = true;
    },
    LoadingAvatar() {
      // console.log('avatar loaded')
      this.isAvatarActive = false
      this.isLoading = false
    },
    async getInterestsAll() {

      await axios.get("/api/hobbies/get")

          .then((response) => {

            console.log(response.data.result)
            // console.log(this.InterestsUser)
            response.data.result.forEach(elem => {

              this.InterestsAll.push({
                active: !!this.MainUserHobbies.find(hobb => hobb.id === elem.id),
                id: elem.id,
                name: elem.name
              });

            })

          })
          .catch(error => {
            console.log('ошибка ' + error);
          });

    },
    send() {

      const interests_send = [];

      this.InterestsAll.forEach((elem) => {
        if (elem.active) {
          interests_send.push(Object.assign(elem))
        }
      })

      let input_age = moment(this.selected_age).format('YYYY')
      let date = new Date();
      let now_year = date.getFullYear()

      let age = (+now_year) - (+input_age)

      if (age < 18) {

        this.err_too_young = true;
        this.err_required_block = true;
        return
      }

      if (this.name == '' || this.selected_age == null) {
        this.err_required = true;
        this.err_required_block = true;
      } else {
        this.err_required_block = false;
        let info = {
          user_id: this.$store.getters.MAINUSER.id,
          name: this.name,
          age: age,
          dob: this.selected_age,
          hobbies: interests_send,
          gender: this.gender,
          about: this.message,
          work: this.work
        };

        let json = JSON.parse(JSON.stringify(info));
        // return;
        axios.post("/api/user/update", {info: json})
            .then(async (response) => {
              console.log("/api/user/update")
              console.log(response);
              await this.$store.dispatch('GET_MAINUSER_BY_ID', this.$store.getters.MAINUSER.id)
              this.isActive = false
            })
            .catch(error => {
              console.log(error);
            });
      }
    }

  },
  mounted() {

    // this.getUserGall();
    this.getInterestsAll();
  }

}


</script>

<style>
.select__gender {
  width: 100%;
}

.area__block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  margin-top: 24px;
}

.text__area {
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.text__area textarea {
  min-height: 120px !important;
  border-radius: 12px;
  background: #f1f0ef;
  border: none;
  padding: 12px;
}

.filepond--wrapper {
  width: 50%;
  margin: 0 auto;
  min-height: 50vh;
  background: #f1f0ef;
  border-radius: 10px;

}

.profile__settings .prof__card .card__gender img {
  width: 14px;
}

.filepond--root.filepond--hopper {
  height: 50vh !important;
  width: 100%;
  display: flex;
  align-items: center;
}

.filepond--drop-label {
  min-height: 100% !important;
}

.setup__pop {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>


