<template>
    <div class="settings-container">
        <div id="settings">
            <div class="settings__left">
                <div class="settings__name">
                    Настройки поиска
                </div>
        <div class="range__set">

<!--            <div class="max__distance">-->
<!--                <div class="range__text">-->
<!--                    <span>Максимальное расстояние</span>-->
<!--                    <div class="text__right">-->
<!--                        {{ value_distance }} км.-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="range__slider" id="select__range">-->
<!--                    <Slider-->
<!--                            ref="slider"-->
<!--                            v-model="value_distance"-->
<!--                            v-bind="options"-->
<!--                    ></Slider>-->
<!--                </div>-->
<!--            </div>-->
          <div class="gender__set">
            <button
                class="gender__btn"
                :class="{ active: search_female === 1 }"
                @click="toggleGender('female')"
            >
              <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.33188 3.31C8.96977 3.31 9.48688 2.79289 9.48688 2.155C9.48688 1.51711 8.96977 1 8.33188 1C7.69399 1 7.17688 1.51711 7.17688 2.155C7.17688 2.79289 7.69399 3.31 8.33188 3.31Z" fill="#0F253E"/>
                <path d="M11.1425 9.8198L9.48798 5.27344L9.47846 5.22668C9.47846 5.16032 9.53418 5.10656 9.60362 5.10656C9.6627 5.10656 9.71226 5.14604 9.72542 5.19868L10.8502 8.1398C10.9249 8.30808 11.2324 8.4198 11.4345 8.4198C11.7047 8.4198 11.7327 7.8892 11.7269 7.8598L10.6021 5.02984C10.5044 4.37996 9.84806 3.7998 9.09906 3.7998H7.63326C6.88426 3.7998 6.1795 4.37996 6.0815 5.02984L5.00714 7.8948C4.98278 7.95052 5.00714 8.43744 5.29946 8.43744C5.52654 8.43744 5.82866 8.34952 5.88354 8.14876L6.96854 5.19084C6.9859 5.14436 7.03266 5.10908 7.0867 5.10908C7.15558 5.10908 7.2113 5.16172 7.2113 5.2278L7.20374 5.27008L5.5915 9.82036C5.58814 9.8338 5.5915 10.1564 5.5915 10.1701C5.5915 10.267 5.82558 10.5201 5.92694 10.5201H6.96686V14.0467C6.96686 14.3379 7.22334 14.5801 7.52686 14.5801C7.83038 14.5801 8.08686 14.3376 8.08686 14.0467V10.3773C8.08686 10.298 8.64686 10.3006 8.64686 10.3798V14.0198C8.64686 14.311 8.90362 14.5798 9.20686 14.5798C9.51094 14.5798 9.76686 14.3107 9.76686 14.0198V10.5198H10.8502C10.9515 10.5198 11.1425 10.2667 11.1425 10.1698C11.1425 10.1474 11.1506 9.83912 11.1425 9.8198Z" fill="#0F253E"/>
              </svg>
              <span class="icon">Женщины</span>
            </button>

            <button
                class="gender__btn"
                :class="{ active: search_male === 1 }"
                @click="toggleGender('male')"
            >
              <svg width="32" height="32" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.12 14.0198C7.12 14.365 7.33504 14.5798 7.68 14.5798C8.0258 14.5798 8.24 14.365 8.24 14.0198V8.8398H8.8V14.0198C8.8 14.3645 9.01476 14.5798 9.36 14.5798C9.70524 14.5798 9.92 14.365 9.92 14.0198V5.1998H10.2V8.36772C10.2 9.03832 11.0417 9.03832 11.04 8.36772V4.96488C11.04 4.224 10.5063 3.7998 9.64 3.7998H7.4C6.6104 3.7998 6 4.14112 6 4.94416V8.5598C6 9.1198 6.84 9.1198 6.84 8.5598V5.1998H7.12V14.0198Z" fill="#73767C"/>
                <path d="M8.48496 3.31C9.12284 3.31 9.63996 2.79289 9.63996 2.155C9.63996 1.51711 9.12284 1 8.48496 1C7.84707 1 7.32996 1.51711 7.32996 2.155C7.32996 2.79289 7.84707 3.31 8.48496 3.31Z" fill="#73767C"/>
              </svg>
              <span class="icon">Мужчины</span>
            </button>
          </div>
            <div class="age__rage">
                <div class="range__text">
                    <span>Возрастной диапазон</span>
                    <div class="text__right">
<!--                        {{ search_age }}-->
                    </div>
                </div>
              <div class="range__numbers">
                <span>18</span>
                <span>100</span>
              </div>
                <div class="range__slider" id="select__age">
                    <Slider
                            ref="slider2"
                            v-model="value_age"
                            :min="18"
                            :max="100"
                            :tooltipPosition="'bottom'"
                    ></Slider>
                </div>
            </div>

        </div>

    </div>
<!--    <div class="settings__right">-->
<!--        <div class="settings__name">-->
<!--            PUSH-уведомления-->
<!--        </div>-->
<!--        <div class="push__settings">-->
<!--            <div class="push__block">-->
<!--                <div class="push__name">Совпадения</div>-->
<!--                <div class="push__controller">-->
<!--                    <div class="onoffswitch">-->
<!--                        <input-->
<!--                                type="checkbox"-->
<!--                                name="p-match"-->
<!--                                class="onoffswitch-checkbox"-->
<!--                                id="p-match"-->
<!--                                v-model="notification_new_match"-->
<!--                                true-value="1"-->
<!--                                false-value="0"-->
<!--                        >-->
<!--                        <label class="onoffswitch-label" for="p-match"></label>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->

<!--            <div class="push__block">-->
<!--                <div class="push__name">Новые сообщения</div>-->
<!--                <div class="push__controller">-->
<!--                    <div class="onoffswitch">-->
<!--                        <input-->
<!--                                type="checkbox"-->
<!--                                name="p-message"-->
<!--                                class="onoffswitch-checkbox"-->
<!--                                id="p-message"-->
<!--                                v-model="notification_new_message"-->
<!--                                true-value="1"-->
<!--                                false-value="0"-->
<!--                        >-->
<!--                        <label class="onoffswitch-label" for="p-message"></label>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->


<!--        </div>-->
<!--        </div>-->


            <div class="save__setup desk" @click='send()'>
                <span>Сохранить</span>
            </div>
        </div>
<!--    </div>-->

    </div>
</template>


<script>
    //todo переписать вывод search_age
    import axios from 'axios';
    import Slider from '@vueform/slider'

    // import 'vue-slider-component/theme/antd.css'

    export default {
        components: {
          Slider
        },
        data () {
            return {
                // prefs : [],
                interests:'',

                show_in_search:0,
                search_male:0,
                search_female:0,
                notification_new_match:0,
                notification_new_message:0,
                minAge:'18',
                maxAge:'90',
    			     maxDistance: "3000",
                value_distance: 3000,
                value_age: [18,90],
                options_age: {
                    dotSize: 14,
                    width: 'auto',
                    height: 4,
                    contained: false,
                    direction: 'ltr',
                    data: null,
                    min: 18,
                    max: 90,
                    interval: 1,
                    disabled: false,
                    clickable: true,
                    duration: 0.5,
                    adsorb: false,
                    lazy: false,
                    tooltip: 'focus',
                    tooltipPlacement: 'top',
                    tooltipFormatter: void 0,
                    useKeyboard: false,
                    enableCross: true,
                    fixed: false,
                    minRange: void 0,
                    maxRange: void 0,
                    order: true,
                    marks: false,
                    dotOptions: void 0,
                    process: true,
                    dotStyle: void 0,
                    railStyle: void 0,
                    processStyle: void 0,
                    tooltipStyle: void 0,
                    stepStyle: void 0,
                    stepActiveStyle: void 0,
                    labelStyle: void 0,
                    labelActiveStyle: void 0,
                },
                options: {
                    dotSize: 12,
                    width: 'auto',
                    height: 8,
                    contained: false,
                    direction: 'ltr',
                    data: null,
                    min: 0,
                    max: 3000,
                    interval: 5,
                    disabled: false,
                    clickable: true,
                    duration: 0.5,
                    adsorb: false,
                    lazy: false,
                    tooltip: 'focus',
                    tooltipPlacement: 'top',
                    tooltipFormatter: void 0,
                    useKeyboard: false,
                    enableCross: true,
                    fixed: false,
                    minRange: void 0,
                    maxRange: void 0,
                    order: true,
                    marks: false,
                    dotOptions: void 0,
                    process: true,
                    dotStyle: void 0,
                    railStyle: void 0,
                    processStyle: void 0,
                    tooltipStyle: void 0,
                    stepStyle: void 0,
                    stepActiveStyle: void 0,
                    labelStyle: void 0,
                    labelActiveStyle: void 0,
                }
            }
        },
        computed : {

          mainuser() {
          return this.$store.state.mainuser
          }
        },

        methods: {

          toggleGender(gender) {
            if (gender === 'female') {
              this.search_female = this.search_female === 1 ? 0 : 1;
            } else if (gender === 'male') {
              this.search_male = this.search_male === 1 ? 0 : 1;
            }
          },

            send () {
              // return(1);
                // let search_interests=''
                // let that=this;
                // if (this.search_men==1 && this.search_women==1)
                //     search_interests='Мужчин,Женщин'
                // else {
                //     if (this.search_men == 1 && this.search_women==0) {
                //         search_interests='Мужчин'
                //     } else {
                //         if (this.search_women==1 && this.search_men == 0)
                //             search_interests='Женщин'
                //
                //     }
                // }
                // console.log('show_in_search')
                // console.log(this.show_in_search)
                // if (this.show_in_search==0){
                //     axios.post(r("api.user.profileUpdate"), {
                //         view: this.show_in_search,
                //
                //     })
                //         .then(response => {
                //             // console.log(response);
                //             this.$store.dispatch('GET_MAINUSER').then(() => {
                //             })
                //         })
                //         }
                let info = {
                    user_id: this.$store.getters.MAINUSER.id,
                  search_male: +this.search_male,
                  search_female: +this.search_female,
                  search_age_from: this.value_age[0],
                  search_age_to: this.value_age[1],
                    distance: this.value_distance,
                    notification_new_match: this.notification_new_match,
                    notification_new_message:this.notification_new_message
                };
              console.log(info)
                //
                // let json = JSON.stringify(info);
                // console.log(json)
                // if (that.notification_new_match==1||that.notification_new_message==1) {
                //     OneSignal.getUserId().then(function (userId) {
                //         // console.log("OneSignal User ID:", userId);
                //
                //         axios({
                //             url: r("auth.setPlayerId"), data: {PlayerId: userId}, method: 'POST',
                //             headers: {
                //                 'Content-Type': 'application/json'
                //             }
                //         })
                //             .catch(err => {
                //                 console.log(err)
                //             })
                //     });
                // }
                //
                //
                axios.post("/api/user/preference/update", {info:info})
                    .then(response => {
                        console.log(response);
                      this.$store.dispatch('GET_MAINUSER_PREFERENS')
                        this.$store.dispatch('GET_USERS').then(() => {
                            this.$router.push('/search')

                        })
                    })
                //     .catch(error => {
                //         console.log(err);
                //     });
                // console.log(this.$store.getters.MAINUSER.id)
                // console.log(this.value_distance)
                // console.log(this.value_age.join('-'))
                // console.log(this.notification_new_match)
                // console.log(this.notification_new_message)

            }



    },
    mounted() {

      this.value_distance=this.mainuser.prefs.distance
      this.value_age=[this.mainuser.prefs.search_age_from,this.mainuser.prefs.search_age_to]
      this.search_male=Number(this.mainuser.prefs.search_male)
      this.search_female=Number(this.mainuser.prefs.search_female)
      this.notification_new_match=this.mainuser.prefs.notification_new_match
      this.notification_new_message=this.mainuser.prefs.notification_new_message
    }
    }
</script>
