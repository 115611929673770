<template>
    <div>
        <div id="scroll">
            <div class="check__couple">
                <div class="subscribe__btn" v-bind:class="{hidden: isHidden,hidden: isHiddenButton}" v-on:click="showHidden">
                    <span>Узнайте свои пары</span>
                </div>


                <div class="noMatches" v-bind:class="{active: noMatches}">
                    <span>Ставьте лайки тем, кто нравится. Если случится мэтч - сможете начать общение</span>
                </div>
                <div class="photo__wrapper" v-bind:class="{hidden: isHidden}">
                    <div class="some__user" v-for="match in matches">
                        <div v-bind:data-id="match.id" class="match_item" v-bind:style="{ 'background-image': 'url(' + match.image_url + ')' }">
                            <!--<img v-bind:src="match.image_url" style="max-width:100%"></img>-->
                        </div>
                    </div>

                </div>
            </div>

            <div class="subs__couple" v-bind:class="{active: isActive}">
                <div class="photo__wrapper">
                    <div class="concrete__user" v-for="match in matches">
                        <div v-bind:data-id="match.id" class="match_item" v-on:click="storeMatch(match.match_id)">
                            <div class="new_like" v-if="match.new_user=='Y'"></div>
                            <router-link
                                    tag="a"
                                    class="button"
                                    :to="{path: '/profile/' + match.user.id}"

                            >
                                <div class="without__actions">
                                  <img
                                      class="bg-img"
                                      style="width: 100% !important;"
                                      :src="match.user.avatar || '/assets/images/project/users/placeholder.jpg'"
                                      @error="handleImageError"
                                      alt="background"
                                  />
                                    <!--<img v-bind:src="match.image_url"></img>-->

                                    <div class="concrete__info">
                                        <div class="concrete__name">
                                            {{match.user.name}}
                                        </div>
                                    </div>
                                </div>
                            </router-link>

                            <div class="with__action">
                                <div class="cancel__btn" v-on:click="DeleteMatch(match.match_id)">
                                  <img src="@/assets/images/project/reject-icon.png" alt="dislike">
                                </div>

                                <div class="user__avatar">
                                  <img
                                      class="bg-img"
                                      :src="match.user.avatar || '/assets/images/project/users/placeholder.jpg'"
                                      @error="handleImageError"
                                      alt="background"
                                  />
                                    <router-link
                                            tag="a"
                                            class="link_100"
                                            :to="{path: '/profile/' + match.user.id}"

                                    >
                                    <!--<img v-bind:src="match.image_url">-->
                                    </router-link>
                                </div>

                                <router-link
                                        tag="a"
                                        class="button"
                                        :to="{ path: '/chat/' + match.user.id}"

                                >
                                    <div class="chat__btn">
                                      <img src="@/assets/images/project/menu-icon-message.png" alt="message">
                                    </div>
                                </router-link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import image from '../../assets/images/project/main__logo.png'
    export default {

      components:{image},
        data: function () {
            return {
                isHidden: false,
                isHiddenButton:false,
                noMatches: false,
                isActive: false,
                noMoney: false,
                noSubscribe: false,
                subscribeError:false,
                subscribeSucsess:false
            }
        },
      props: ['nonce'],

        computed: {
          matches() {
            return this.$store.getters.MATCHES
          }
        },
        mounted: function () {
            // let free = localStorage.getItem('free')
            //
            // let trial = localStorage.getItem('trial')
            //
            // if (free == 'N' && trial == 'N') {
                this.isActive = true;
                this.isHidden = true;
                this.isHiddenButton= true;
            // }
            // else
            // if (free == 'N' && trial == 'Y') {
            //     this.isActive = false;
            //     this.isHidden = false;
            //     this.isHiddenButton= true;
            //     this.noMoney = true
            // }
            let that = this

            if (that.matches.length<1)
                this.getmatches()

        },
        methods: {
          handleImageError(event) {
            event.target.src = '/assets/images/project/users/placeholder_user.svg'
          },
            Subscribe(){
                let phone=this.$store.getters.MAINUSER.email.replace('@love.beeline.kz','')
                // console.log(phone)

                axios({url: r("api.user.subscribe"), data: {phone:phone}, method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(resp => {
                        // console.log('response')
                        // console.log(resp);
                        //resp.data.request.code==11 ||
                        if (resp.data.request.code==1 ||  resp.data.request.result=="error"){this.subscribeError=true}
                        else {
                            this.noSubscribe = false
                            localStorage.setItem('free','N')
                            localStorage.setItem('trial','N')
                            this.isActive = true;
                            this.isHidden = true;
                            this.isHiddenButton= true;
                        }


                    })
                    .catch(err => {
                        console.log('error')
                        console.log(err);
                        this.subscribeError=true
                    })
            },
            showHidden() {
                let free = localStorage.getItem('free')
                let trial = localStorage.getItem('trial')
                if (free == 'N' && trial == 'N') {
                    this.isActive = true;
                    this.isHidden = true;
                } else if (free == 'N' && trial == 'Y') {
                    this.isActive = false;
                    this.isHidden = false;
                    this.isHiddenButton= true;
                    this.noMoney = true
                } else if (free == 'Y') {
                    this.isActive = false;
                    this.isHidden = false;
                    this.noMoney = false;
                    this.noSubscribe = true
                }
            },
            storeMatch(id) {
                console.log(id);
                // localStorage.setItem('id_to', id);
                // axios.post(r("api.user.matches.update"), {
                //     id: id,
                //
                // })
                //     .then(
                //         this.$store.dispatch('GET_NEW_MATCHES')
                //     )
            },
            DeleteMatch(id) {
                axios.post("/api/user/matchesDelete", {id:id})

                    .then((response) => {
                        // console.log(response);
                        // console.log(response.data[0].user2)

                        for (let i = 0; i < this.matches.length; i++) {

                            if (this.matches[i].user_id == response.data.user2) {
                                // console.log('find ' + this.matches[i].id)
                                this.matches.splice(i, 1);
                            }

                        }
                        // console.log(this.matches)

                    })
                    .catch(error => {
                        console.log('ошибка ' + error);
                    });
            },
            getmatches() {

                let that = this;
                if(this.$store.getters.MATCHES.length<1) {
                  this.$store.dispatch('GET_MATCHES').then(()=>{
                    if (this.$store.getters.MATCHES.length<1){
                      that.isHidden = true;
                      that.noMatches = true
                  }
                  })
                }

            },
        }
    };
</script>

<style :nonce="nonce">
.main__content.off #scroll {
  /*opacity: 0;*/
}

.check__couple .subscribe__btn {
  width: 257px;
  height: 59px;
  color: #ffffff;
  background: -webkit-gradient(linear, left top, right top, from(#fabd00), to(#fbca33));
  background: linear-gradient(to right, #fabd00 0%, #fbca33 100%);
  border-radius: 666px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 22px;
  font-weight: 400;
  margin: 15px auto 20px;

  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  text-align: center;
}

.check__couple .subscribe__btn:active, .check__couple .subscribe__btn.focus {
  font-size: 23px;
}

.check__couple .subscribe__btn.hidden {
  display: none;
}

.check__couple .photo__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 400px;
  margin: 0 auto;
  padding:0 10px;
}

.check__couple .photo__wrapper.hidden {
  display: none;
}

.check__couple .photo__wrapper .some__user {
  overflow: hidden;
  width: 152px;
  height: 245px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  margin: 10px;
  background: #f7f8fb;
  border: 1px solid #ccc;
}

/*.check__couple .photo__wrapper .some__user:not(:first-child) {*/
/*margin-left: 30px;*/
/*}*/

/*.check__couple .photo__wrapper .some__user:nth-child(2n + 3) {*/
/*margin-left: 0;*/
/*}*/

.check__couple .photo__wrapper .some__user  {
  /*width: 100%;*/
  /*height: 100%;*/
  -webkit-filter: blur(15px);
  filter: blur(15px);
}

.subs__couple {
  display: none;
  padding-top: 22px;
}

.subs__couple.active {
  display: block;
}

.subs__couple .photo__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 95%;
  margin: 0 auto;
  padding:0 10px;
}

.subs__couple .concrete__user {
  position: relative;
  margin-bottom: 40px;
  background: #f7f8fb;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
}

.subs__couple .concrete__user:not(:first-child) {
  /*margin-left: 30px;*/
}

.subs__couple .concrete__user:nth-child(2n + 3) {
  /*margin-left: 0;*/
}

.subs__couple .concrete__user .without__actions {
  overflow: hidden;
  width: 152px;
  height: 245px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  margin: 0 auto;
  /*margin-bottom: 15px;*/
  position: relative;
}

.subs__couple .concrete__user .without__actions img {
  width: 100%;
  height: 100%;
}

.subs__couple .concrete__user .without__actions .concrete__info {
  position: absolute;
  bottom: 35px;
  font-size: 12px;
  width: 90%;
}

.subs__couple .concrete__user .without__actions .concrete__info .concrete__name {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 400;
  color: #fff;
}

.subs__couple .concrete__user .without__actions .concrete__info .concrete__dop {
  height: 22px;
}

.subs__couple .concrete__user .without__actions .concrete__info .concrete__dop img {
  width: 12px;
  height: 12px;
}

.subs__couple .photo__wrapper .concrete__user .with__action {
  width: 163px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: distribute;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  /*bottom: -51px;*/
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.subs__couple .photo__wrapper .concrete__user .with__action .cancel__btn, .subs__couple .photo__wrapper .concrete__user .with__action .chat__btn {
  width: 33px;
  height: 33px;
  background: #ffffff;
  border-radius: 666px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 22px;
  padding-left: 2px;
  padding-bottom: 2px;
}

.subs__couple .photo__wrapper .concrete__user .with__action .cancel__btn {
  color: #d91a00;
  -webkit-box-shadow: 0 0 8px 2px rgba(217, 26, 0, 0.25);
  box-shadow: 0 0 8px 2px rgba(217, 26, 0, 0.25);
}

.subs__couple .photo__wrapper .concrete__user .with__action .chat__btn {
  color: #84be41;
  -webkit-box-shadow: 0 0 8px 2px rgb(0 0 0 / 25%);
  box-shadow: 0 0 8px 2px rgb(0 0 0 / 25%);
}

.subs__couple .photo__wrapper .concrete__user .with__action .cancel__btn img {
  width: 100%;
}

.subs__couple .photo__wrapper .concrete__user .with__action .chat__btn img {
  width: 100%;
  padding: 10px;
}

.subs__couple .photo__wrapper .concrete__user .with__action .user__avatar {
  width: 52px;
  height: 52px;
  margin: 0px 7px;
  overflow: hidden;
  border-radius: 666px;
  border-top: 1px solid #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #f7f8fb;
}

.subs__couple .photo__wrapper .concrete__user .with__action .user__avatar img {
  width: 100%;
}

@media screen and (min-width: 980px) {
  /*.concrete__user:nth-child(2n + 3) {*/
  /*margin-left: 30px !important;*/
  /*}*/
  /*.concrete__user:nth-child(4n + 5) {*/
  /*margin-left: 0 !important;*/
  /*}*/
  .main__content.off #scroll {
    opacity: 1;
    /*padding-top: 32px;*/
  }
  .check__couple .photo__wrapper {
    max-width: 900px;
    /*margin-top: 20px;*/
  }
  .check__couple .photo__wrapper.hidden {
    display: none;
  }
  .check__couple .photo__wrapper .some__user {
    width: 197px;
    height: 307px;
  }
  /*.check__couple .photo__wrapper .some__user:nth-child(2n + 3) {*/
  /*margin-left: 30px !important;*/
  /*}*/
  /*.check__couple .photo__wrapper .some__user:nth-child(4n + 5) {*/
  /*margin-left: 0 !important;*/
  /*}*/
  .check__couple .photo__wrapper .some__user  {
    -webkit-filter: blur(15px);
    filter: blur(15px);
  }
  .subs__couple .photo__wrapper {
    max-width: 900px;
    margin-top: 20px;
  }
  .subs__couple .concrete__user .without__actions {
    width: 197px;
    height: 332px;
  }
  .subs__couple .photo__wrapper .concrete__user .with__action {
    width: 197px;
    bottom: -68px;

  }
  .subs__couple .photo__wrapper .concrete__user .with__action .cancel__btn, .subs__couple .photo__wrapper .concrete__user .with__action .chat__btn {
    width: 42px;
    height: 42px;
    font-size: 26px;
  }
  .subs__couple .photo__wrapper .concrete__user .with__action .user__avatar {
    width: 72px;
    height: 72px;
    margin: 0px 15px;
  }
}
/*# sourceMappingURL=dynamic.css.map */

.noMoney {
  display: none;
  align-items: center;
  height: 70px;
  cursor: pointer;
  /*margin: 25px auto 20px;*/
  margin: 50px auto 30px;
}

.noMoney span{
  background: rgba(255, 0, 0, 0.7);
  padding: 15px;
  margin: 15px auto 20px;
  width: 261px;
  border-radius: 666px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}
.noMoney.active {
  display: flex;

}
.noSubscribe {
  display: none;
  align-items: center;
  height: 70px;

}

.noSubscribe span{
  background: rgba(255, 0, 0, 0.7);
  padding: 15px;
  margin: 0 auto;
  width: 230px;
  border-radius: 10px;
  text-align: center;
}
.noSubscribe.active {
  display: flex;

}
.noMatches {
  display: none;
  align-items: center;
  height: 70px;

}

.noMatches span{
  color: #000;
  padding: 15px;
  margin: 0 auto;
  width: 230px;
  border-radius: 10px;
  text-align: center;
}
.noMatches.active {
  display: flex;
  height: 100%;
  justify-content: center;
}

</style>