<template>
    <div class="cards">
        <User
                v-for="(user, index) in users"
                :key="user.id"
                :user="user"
                :is-current="index === 0"
                @cardAccepted="$emit('cardAccepted');"
                @cardRejected="$emit('cardRejected');"
                @cardSkipped="$emit('cardSkipped');"
                @hideCard="$emit('hideCard');"
        />
    </div>
</template>

<script>
    import User from "../User/index.vue";

    export default {
      name:'UserPack',
        components: {
            User
        },

        props: {
            users: {
                required: true
            }
        }
    };
</script>


