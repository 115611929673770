
<template>

    <div
            v-if="isShowing"
            ref="interactElement"
            :class="{
      isAnimating: isInteractAnimating,
isCurrent: isCurrent
    }"
            class="card cards__actions"
            :style="{ transform: transformString }"
    >
        <div  v-bind:data-id="user.id" v-bind:data-index="user.index" class="card_child found__user">
            <div style="color:#000" v-if="is_like=='like'" class="like_overlay"></div>
            <div style="color:#000" v-if="is_like=='dislike'" class="dislike_overlay"></div>
            <div class="all__photos" >
              <img
                  :src="user.avatar || '/assets/images/project/users/placeholder_user.svg'"
                  alt="avatar"
                  @error="handleImageError">
            </div>

            <div class="default__avatar">
               
            </div>
            <div class="found__data">
                <div class="some__name">{{user.name}}</div>

                <div class="some__detail">
              <span class="found__gender" v-if="user.gender == 1">
            <img :src="female_icon" alt="gender">
          </span>
                    <span class="gender__img" v-if="user.gender == 0">
            <img :src="female_icon" alt="gender">
          </span>
                    <span class="found__dop">
                <span class="found__age">{{user.age}} {{ addAgeWord(user.age) }}</span>
<!--в {{distance}} км от вас-->
              </span>

                </div>



            </div>


 

        </div>

    </div>

</template>

<script>
    //
    import interact from "interactjs";
    import male_icon from '../../assets/images/project/gender/male.png'
    import female_icon from '../../assets/images/project/gender/female.png'
    const ACCEPT_CARD = "cardAccepted";
    const REJECT_CARD = "cardRejected";
    const SKIP_CARD = "cardSkipped";

    export default {

        static: {
            interactMaxRotation: 10,
            interactOutOfSightXCoordinate: 100,
            interactOutOfSightXCoordinate: document.documentElement.clientWidth*2/3,
            interactOutOfSightYCoordinate: 100,
            interactYThreshold: 200,
            interactXThreshold: 10
        },

        props: {
            user: {
                type: Object,
                required: true
            },
            isCurrent: {
                type: Boolean,
                required: true
            },
          nonce:['nonce'],
        },

        data() {

            return {
              male_icon:male_icon,
              female_icon:female_icon,
                isShowing: true,
                isInteractAnimating: true,
                isInteractDragged: null,
                is_like:'',
                interactPosition: {
                    x: 0,
                    y: 0,
                    rotation: 0
                },
                distance:''
            };
        },

        computed: {
            transformString() {
                if (!this.isInteractAnimating || this.isInteractDragged) {
                    const { x, y, rotation } = this.interactPosition;
                    return `translate3D(${x-165}px, ${y}px, 0) rotate(${rotation}deg)`;
                }

                return null;
            }
        },

        mounted() {
            const element = this.$refs.interactElement;

// console.log(element);
//             console.log(this.user)
            this.distance=this.user.distance/1000;
            interact(element).draggable({
                onstart: () => {
                this.isInteractAnimating = false;
        },

            onmove: event => {
                const {
                    interactMaxRotation,
                    interactXThreshold
                } = this.$options.static;
                const x = this.interactPosition.x + event.dx;
                const y = this.interactPosition.y + event.dy;
                let rotation = interactMaxRotation * (x / interactXThreshold);

                if (rotation>-interactMaxRotation||rotation<interactMaxRotation){this.is_like='';}
                if (rotation > interactMaxRotation) {rotation = interactMaxRotation;this.is_like='like';}
                else if (rotation < -interactMaxRotation)
                {rotation = -interactMaxRotation;
                    this.is_like='dislike';}

                this.interactSetPosition({ x, y, rotation });
            },

            onend: () => {
                const { x, y } = this.interactPosition;
                const { interactXThreshold, interactYThreshold } = this.$options.static;
                this.isInteractAnimating = true;

                if (x > (interactXThreshold)) {this.playCard(ACCEPT_CARD);
                // console.log('x:'+x);
                // console.log('x>:'+(interactXThreshold))

                }
                else if (x < (-interactXThreshold)) {this.playCard(REJECT_CARD);
                // console.log('x:'+x);
                // console.log('x<interactXThreshold:'+(-interactXThreshold))
                }
                else if (y < -interactYThreshold) {this.playCard(SKIP_CARD);
                // console.log('y:'+y);
                // console.log('y>interactYThreshold:'+(-interactYThreshold))
                }
                else {this.resetCardPosition()};


            }
        });
        },

        beforeDestroy() {
            interact(this.$refs.interactElement).unset();
        },

        methods: {

            addAgeWord(age) {
                if (age % 10 === 1 && age % 100 !== 11) {
                    return 'год';
                } else if ([2, 3, 4].includes(age % 10) && ![12, 13, 14].includes(age % 100)) {
                    return 'года';
                } else {
                    return 'лет';
                }
            },

            hideCard() {
                setTimeout(() => {
                    this.isShowing = false;
                this.$emit("hideCard", this.user);
            }, 300);
            },

            playCard(interaction) {
                const {
                    interactOutOfSightXCoordinate,
                    interactOutOfSightYCoordinate,
                    interactMaxRotation
                } = this.$options.static;

                this.interactUnsetElement();

                switch (interaction) {
                    case ACCEPT_CARD:
                        this.interactSetPosition({
                            x: interactOutOfSightXCoordinate,
                            rotation: interactMaxRotation
                        });
                        this.$emit(ACCEPT_CARD);

                        break;
                    case REJECT_CARD:
                        this.interactSetPosition({
                            x: -interactOutOfSightXCoordinate,
                            rotation: -interactMaxRotation
                        });
                        this.$emit(REJECT_CARD);


                        break;
                    case SKIP_CARD:
                        this.interactSetPosition({
                            y: interactOutOfSightYCoordinate
                        });
                        this.$emit(SKIP_CARD);
                        // console.log("SKIP_CARD");

                        break;
                }

                this.hideCard();
            },

            interactSetPosition(coordinates) {
                const { x = 0, y = 0, rotation = 0 } = coordinates;
                this.interactPosition = { x, y, rotation };
            },
 
            interactUnsetElement() {
                interact(this.$refs.interactElement).unset();
                this.isInteractDragged = true;
            },

            resetCardPosition() {
                this.interactSetPosition({ x: 0, y: 0, rotation: 0 });
            },

          handleImageError(event) {
            event.target.src = '/assets/images/project/users/placeholder_user.svg'
          }
        }
    };
</script>

<style lang="scss" scoped :nonce="nonce">
    @import "../../assets/styles/index.scss";

    $cardsTotal: 3;
    $cardsPositionOffset: 20vh * 0.06;
    $cardsScaleOffset: 0.08;
    $defaultTranslation: $cardsPositionOffset * $cardsTotal;
    $defaultScale: 1 - ($cardsScaleOffset * $cardsTotal);


    .card {

        @include absolute(0);

        @include flex-center();



        display: flex;

        opacity: 0;


        &.isCurrent {
            pointer-events: auto;
        }

        &.isAnimating {
            transition: transform 0.7s $ease-out-back;
        }
    }



    @for $i from 1 through $cardsTotal {
        $index: $i - 1;
        $translation: $cardsPositionOffset * $index;
        $scale: 1 - ($cardsScaleOffset * $index);

        .card:nth-child(#{$i}) {
            z-index: $cardsTotal - $index;
            opacity: 1;


        }
    }
</style>
