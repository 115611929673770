/* eslint-disable promise/param-names */
import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT,
    AUTH_BLOCKED,
    AUTH_UNREGISTERED,
    AUTH_ERROR_LOGIN,
    AUTH_CLEAR, AUTH_ERROR_PASSWORD
} from '../actions/auth'
import {USER_REQUEST} from '../actions/user'
import axios from 'axios';
// import r from '@/js/route';
// import apiCall from '@/js/utils/api'
// console.log('test-imput '+{USER_REQUEST});
const state = {
    token: localStorage.getItem('user-token') || null,
    status: '',
    hasLoadedOnce: false,
    blocked: false,
    error_login: false,
    error_password: false,
    unregistered: false
}

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    blocked: state => state.blocked,
    error_login: state => state.error_login,
    error_password: state => state.error_password,
    unregistered: state => state.unregistered,
}

const actions = {

    [AUTH_REQUEST]: ({commit, dispatch}, user) => {

        return new Promise((resolve, reject) => {
            console.log('test-imput ');
            console.log(user)
            commit(AUTH_REQUEST)
            axios({
                url: ("/api/login"), data: user, method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(
                    resp => {
                        console.log(resp.data.result)
                        //       if (resp.data.response=='blocked')
                        //
                        //       {commit(AUTH_BLOCKED)
                        //       }
                        //       else
                        if (resp.data.result == 'fail_login') {
                            commit(AUTH_ERROR_LOGIN)
                            resolve('fail_login')
                        }
                        else if (resp.data.result == 'fail_password') {
                            commit(AUTH_ERROR_PASSWORD)
                            resolve('fail_password')
                        }
                        //         else
                        //         if (resp.data.response=='unregistered')
                        //
                        //         {commit(AUTH_UNREGISTERED)
                        //         }
                              else
                                {
                        localStorage.setItem('user-token', resp.data.result.token)
                        localStorage.setItem('id', resp.data.result.id)

                        //           localStorage.setItem('count', resp.data.counts)
                        //           if (resp.data.active=='N')
                        //           localStorage.setItem('free', 'Y')
                        //           else
                        //             localStorage.setItem('free', 'N')
                        //           // console.log('blocked')
                        //           // console.log(resp.data.blocked)
                        //           localStorage.setItem('trial', resp.data.blocked)
                        //           // localStorage.setItem('trial', 'N')
                        //           OneSignal.getUserId().then(function(userId) {
                        //             // console.log("OneSignal User ID:", userId);
                        //             if (userId) {
                        //               axios({
                        //                 url: r("auth.setPlayerId"), data: {PlayerId: userId}, method: 'POST',
                        //                 headers: {
                        //                   'Content-Type': 'application/json'
                        //                 }
                        //               })
                        //             }
                        //
                        //           });
                        commit(AUTH_SUCCESS, resp.data)
                        dispatch(USER_REQUEST)
                        resolve(resp.data)
                              }
                        //
                        //
                    })
                .catch(err => {
                    commit(AUTH_ERROR, err)
                    localStorage.removeItem('user-token')
                    // localStorage.removeItem('id')
                    reject(err)
                })
        })
    },
    [AUTH_LOGOUT]: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_LOGOUT)
            localStorage.removeItem('user-token')
            // localStorage.removeItem('id')
            resolve()
        })
    },
    [AUTH_CLEAR]: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_CLEAR)

            resolve()
        })
    },
}

const mutations = {

    [AUTH_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [AUTH_SUCCESS]: (state, resp) => {
        console.log(typeof resp)
        console.log(resp.result)
        state.error_login = false
        state.error_password = false
        state.status = 'success'
        state.token = resp.result.token
        state.hasLoadedOnce = true
    },
    [AUTH_ERROR]: (state) => {
        state.status = 'error'
        state.hasLoadedOnce = true
    },
    [AUTH_LOGOUT]: (state) => {
        state.token = ''
    },

    [AUTH_BLOCKED]: (state) => {
        state.blocked = true
        // console.log('AUTH_BLOCKED')
    },
    [AUTH_ERROR_LOGIN]: (state) => {
        state.error_login = true
        state.blocked = false
        // console.log('AUTH_ERROR_LOGIN')
    },
    [AUTH_ERROR_PASSWORD]: (state) => {
        state.error_password = true
        state.blocked = false
        // console.log('AUTH_ERROR_LOGIN')
    },
    [AUTH_UNREGISTERED]: (state) => {
        state.error_login = false
        state.blocked = false
        state.unregistered = true
        // console.log('AUTH_UNREGISTERED')
    },
    [AUTH_CLEAR]: (state) => {
        state.error_login = false
        state.blocked = false
        state.unregistered = false
        // console.log('AUTH_CLEAR_ERRORS')
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
