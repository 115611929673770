import App from './components/views/App.vue'
import router from './routes/index.js'

import MainStore from "./stores";
const store = MainStore.store;
import "./assets/styles/css/all.css";
import "./assets/styles/css/slider.css";
require.context('./assets/images/project/users/', true)




import axios from 'axios'

import { createApp } from 'vue'



const main = createApp(App).use(router).use(store)


const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}


// app.component('Header', Header);
// app.component('Aside', Aside);
// app.component('Footer', Footer);


main.mount('#app')




