<template>

  <div>
    <div class="preloader">
      <img :src="preloader">
    </div>
    <div id="Auth">
      <div class="auth__whites">
        <div class="auth__static">
          <div class="auth__logo">
            <img src="@/assets/images/project/Logo_2.svg" alt="logo">

            <!-- <div v-on:click="isHiddenUser = false"> -->
            <!-- <img src="@/assets/images/project/information-svgrepo.png" class="info" v-on:click="isHiddenUser = false" alt="info"> -->
            <!-- </div> -->
          </div>
        </div>

        <div class="auth__promo">
          <h2>Войди в клуб новых знакомств</h2>
          <p>Собери свою компанию по интересам</p>
        </div>

        <div class="auth__promo-image">
          <img src="@/assets/images/project/Auth-photo.png" alt="Background image">
        </div>

        <div class="all__forms">

          <div class="app__desc">
            <span>
              Войти или зарегистрироваться
            </span>
          </div>
          <div class="err__auth err__active" v-if="error_time">
            <span>Вы сможете запросить код повторно через 2 минуты.</span>
          </div>

          <div class="err__auth err__active" v-if="blocked_err">
            <span>Возможность использования сервиса заблокирована для вашего номера</span>
          </div>

          <div class="err__auth err__active" v-if="alredyRegistered">
            <span>Вы уже зарегистрированы</span>
          </div>

          <div class="auth__dynamic"></div>

          <form class="login" @submit.prevent="send_pin">
            <input
                required
                maxlength="12"
                v-model="username"
                id="login_username"
                type="text"
                placeholder="+7"
                class="auth__input input__phone"
                :class="{'input-error': showPhoneError}"
                @input="handleInputPhone"
            />
            <div class="err__auth err__active" v-if="login_err">
              <span>Неверный номер телефона</span>
            </div>
            <div class="input__phone-status" v-if="showPhoneError">
              <img src="@/assets/images/project/information-svgrepo.png" class="info" alt="info">
              {{ phoneError }}
            </div>

            <button type="submit" class="btn login__btn">Продолжить</button>

          </form>

        </div>
        <div class="read__rules">
          <img src="@/assets/images/project/information-svgrepo.png" class="info" alt="info">
          Нажимая кнопку "Продолжить", Вы принимаете наши <span v-on:click="isHidden = false">Правила</span> и <span
            v-on:click="isHiddenUser = false">Пользовательское соглашение</span>
          <p>Абонентская плата 40 тг/день</p>
        </div>

        <div class="restore__pop" v-bind:class="{hidden: isHiddenRestore}">
          <div class="close__restore" v-on:click="isHiddenRestore = true">
            <i class="icon-cross"></i>
          </div>

          <div class="text__restore">
            Чтобы вспомнить пароль, отправьте REG на номер 361
          </div>
        </div>
      </div>
      <div class="auth__footer">
        <h2>Знакомься прямо сейчас!</h2>
        <p>Общение. Дружба. Отношения.</p>
        <img src="@/assets/images/project/Auth-footer-mob.png" class="m-none" alt="Phones">
        <img src="@/assets/images/project/Auth-footer.png" class="d-none" alt="Phones">
      </div>
    </div>

    <div v-if="isPopupVisible" class="popup_code">
      <div class="otp-container">
        <h2 class="title">{{ status[currentStatus]?.title || 'Введите код' }}</h2>
        <p class="description">
          {{ status[currentStatus]?.description || `Мы отправили СМС с кодом на номер ${username}` }}
        </p>
        <div class="otp-inputs" v-if="!isLoginError" :class="{'error_code':error_code}">
          <input
              v-for="(digit, index) in otp"
              :key="index"
              v-model="otp[index]"
              maxlength="1"
              type="text"
              @input="handleInput(index, $event)"
              @keydown.delete="handleDelete(index, $event)"
              class="otp-input"
              ref="inputs"
          />
        </div>

        <div class="err__auth err__active" v-if="error_register" style="justify-content: center; color: red">
          <span>При регистрации произошла ошибка</span>
        </div>

        <p class="err__auth err__active" v-if="error_code" style="justify-content: center; color: red">
          Неверный код
        </p>

        <button
            class="send-again-btn"
            @click="resendCode"
            :disabled="isCountdownActive"
            v-if="!isLoginError"
        >
          Отправить заново
          <span v-if="isCountdownActive">{{ countdownText }}</span>
        </button>

        <button
            @click="login()"
            :disabled="!isOtpComplete"
            class="submit-btn"
            v-if="!isLoginError"
        >
          Отправить код
        </button>

        <button
            v-if="isLoginError"
            @click="closePopup"
            class="close-btn"
        >
          Закрыть
        </button>
      </div>
    </div>

    <div class="popup__rules " v-bind:class="{hidden: isHidden}">

      <div class="ruls__header">
        <div class="r__text">
          Правила
        </div>

        <div class="r__close" v-on:click="isHidden = true">
          <i class="icon-cross"></i>
        </div>
      </div>


      <div class="ruls__text">

        <div class="text__up">
          Об услуге «LOVE IS»
        </div>

        LOVE IS - это сервис знакомств и поиска друзей по интересам. В любое время и в любом месте,
        при наличии мобильного телефона или компьютера и доступа в интернет, Вы получаете доступ к быстрому поиску анкет
        и общению с понравившимися людьми.
        <br><br>
        Знакомьтесь, общайтесь, встречайтесь!
        <br><br>
        Общение является полностью конфиденциальным: никто не узнает ваш номер телефона, если вы сами не решите его
        сообщить.
        <br><br>
        Просмотр фотографий в сервисе «LOVE IS» работает на мобильных телефонах с поддержкой передачи данных через
        GPRS/EDGE/3G.
        <br><br>
        В рамках сети Kcell трафик мобильного интернета тарифицируется согласно вашему тарифному плану.

        <div class="text__up">
          Основные команды, используемые в Сервисе
        </div>
        <div class="text__up">
          Подписка
        </div>

        Для активации подписки наберите ussd команду *209# , отправьте "СТАРТ" на номер 209 или введите свой номер
        телефона в форму подписки на loveis.pro,
        далее Вы получите код подтверждения , его необходимо будет ввести в предложенную форму,
        что бы подтвердить свое согласие на подписку. После этого вы получите смс-сообщение с информацией о доступе к
        услуге.

        <div class="text__up">
          Получение доступа к Услугам
        </div>

        Для доступа к услуге «LOVE IS» наберите в браузере loveis.pro,
        далее введите своей номер телефона и код, присланный в смс, в окно авторизации.

        <div class="text__up">
          Отписка
        </div>

        Для отказа от пользования сервисом отправьте SMS со словом STOP на номер 209.

        <div class="text__up">
          Стоимость
        </div>

        Стоимость доступа к Услуге: 40 тенге в сутки.
      </div>
    </div>
    <div class="popup__rules" v-bind:class="{hidden: isHiddenUser}">

      <div class="ruls__header">
        <div class="r__text">
          Пользовательское соглашение
        </div>

        <div class="r__close" v-on:click="isHiddenUser = true">
          <i class="icon-cross"></i>
        </div>
      </div>


      <div class="ruls__text">
        <div class="text__up">
          Принимая Соглашение Пользователь:
        </div>

        - Гарантирует, что его возраст не менее 18 лет.- Несет личную ответственность за содержание информации и
        материалов (контент),
        опубликованных им в Сервисе, за сохранность и конфиденциальность данных, необходимых для его авторизации в
        Сервисе.
        - Обязуется использовать Сервис в соответствии с действующим законодательством Республики Казахстан.
        - Обязуется не вводить в заблуждение других Пользователей, а также не преследовать их и не досаждать им;
        не вступать в контакт с другими Пользователями в случае совершения ими действий, явно свидетельствующих о
        нежелании контакта с Пользователем.
        - Обязуется не пытаться без согласования с администрацией Сервиса совершать сделки или предлагать совершать
        сделки с другими пользователями
        в отношении каких-либо товаров, работ или услуг, использовать Сервис для распространения рекламных материалов
        или материалов незаконной пропаганды.
        - Пользователь гарантирует, что контент опубликованный им в Сервисе, не нарушает исключительные (имущественные)
        и неимущественные авторские и смежные права третьих лиц. Пользователь осознает, что если при создании и
        размещении контента им используются
        объекты интеллектуальной собственности, авторские и смежные права на которые принадлежат третьим лицам
        (например,
        музыкальные произведения с текстом или без текста, фонограммы и т.д.), то Пользователю следует получить
        разрешение
        от правообладателей на использование соответствующих объектов интеллектуальной собственности.- В случае
        нарушения Пользователем авторских
        и смежных прав третьих лиц в связи с созданием и размещением контента, Пользователь обязуется собственными
        силами и за свой
        счет разрешить возникшие претензионные ситуации. Дополнительно к указанному выше, Пользователь гарантирует, что
        создаваемый
        и размещаемый им в Сервисе контент соответствует действующему законодательству Республики Казахстан, а также
        общепринятым моральным,
        этическим, культурным, социальным нормам, и его содержание:- не содержит призывов к разжиганию религиозной,
        расовой, межнациональной розни;
        - не содержит призывов к осуществлению террористической или экстремистской деятельности или оправдания
        терроризма;- не содержит призывов
        к развязыванию войны, геноциду, и иному осуществлению вражды и насилия;- не содержит призывов, угрожающих
        действующему государственному,
        конституционному и/или политическому строю Республики Казахстан, а также суверенитету и территориальной
        целостности Республики Казахстан,
        а также призывов, направленных на свержение действующего государственного, конституционного и/или политического
        строя Республики Казахстан;
        - не посягает на честь и достоинство, равенство прав и свобод третьих лиц;- не посягает на неприкосновенность
        частной жизни, частную
        собственность третьих лиц, тайну переписки, телефонных переговоров, почтовых, телеграфных или иных сообщений;-
        не содержит пропаганду
        употребления наркотических средств;- не содержит информацию о способах самоубийства, а также провокации к его
        совершению;
        - не содержит информацию, нарушающую права несовершеннолетних лиц;- не содержит информацию, содержащую
        коммерческую, служебную
        и иную конфиденциальную информацию, персональные данные третьих лиц, государственную тайну и любую другую
        информацию,
        доступ к которой ограничен на основании действующего законодательства Республики Казахстан;- не нарушает иные
        требования
        действующего законодательства Республики Казахстан. В случае нарушения всех вышеуказанных требований
        Пользователь будет
        нести соответствующую административную, гражданскую, уголовную ответственность, установленную действующим
        законодательством
        Республики Казахстан Пользователь может в любое время перестать пользоваться Сервисом без объяснения причин,
        следуя инструкциям, указанным в Сервисе.
        На период своего пользования Сервисом Пользователь передает администрации Сайта исключительное право на
        воспроизведение, распространение, модификацию,
        а также сообщение контента, размещенного Пользователем в Сервисе, по кабелю, проводам или с помощью иных
        аналогичных средств, таким образом,
        при котором любое лицо иметь доступ к нему в интерактивном режиме из любого места и в любое время по своему
        выбору (доведение до всеобщего сведения).
        Пользователь дает согласие на хранение, обработку, использование и распространение указанных Пользователем при
        регистрации
        в Сервисе анкетных данных в рамках и целях функционирования Сервиса на период функционирования Сервиса,
        а также на получение информации рекламно-информационного характера о Сервисе и иных сервисах, не связанных с
        Сервисом «LOVE IS»,
        в том числе посредством SMS, электронной почты, телефону и иным средствам связи.

        <div class="text__up">
          Администрация сервиса оставляет за собой право:
        </div>

        - Модифицировать Сервис по своему усмотрению.- Оказывать платные и бесплатные услуги Пользователям (далее —
        «Услуги»).- Вносить изменения в данное Соглашение в одностороннем порядке, изменять стоимость и виды Услуг,
        сроки их действия.- Редактировать или удалять контент, размещенный Пользователем в Сервисе, если он не
        соответствуют Соглашению, редакционной политике Сервиса, наносит вред Сервису или третьим лицам, нарушает
        законодательство Республики Казахстан.

        <div class="text__up">
          Администрация сервиса не несет ответственности:
        </div>

        За содержание, достоверность и точность контента, опубликованного Пользователями.- За причинение вреда, ущерба,
        потерю информации или за причинение любых других убытков любым лицам, которые возникли при пользовании Сервисом,
        в том числе с использованием мобильных средств связи и иных средств телекоммуникаций.- За нарушение
        Пользователем авторских и иных прав третьих лиц путем опубликования материалов, не соответствующих действующему
        законодательству (в том числе авторскому), размещенных Пользователем в Сервисе.

        <div class="text__up">
          Стоимость и условия подписки:
        </div>

        Стоимость предоставления услуги «LOVE IS» составляет 40 тенге в день. Списание денежных средств со счета
        Пользователя происходит каждый день. При навигации по WEB-версии услуги «LOVE IS» в рамках сети Kcell трафик
        мобильного интернета тарифицируется согласно вашему тарифному плану. Для заказа услуги необходимо набрать ussd
        команду *209# или отправить "СТАРТ" на номер 209, так же можно оформить подписку на сайте loveis.pro Для
        прекращения оказания услуги необходимо наберать ussd команду *209*0# или отправить "СТОП" на номер 209.

      </div>
    </div>
  </div>

</template>


<script>

import {AUTH_REQUEST, AUTH_CLEAR, AUTH_LOGOUT} from '../stores/actions/auth'
import preloader from '../assets/images/project/preloader.svg';
import axios from 'axios';
import {ref} from 'vue';
// import r from '@/js/route';

//
import {mapGetters} from 'vuex';
import {io} from "socket.io-client";
// import {User} from '../../models/user.models';
export default {
  name: 'login',

  data() {
    return {
      preloader: preloader,
      username: '',
      password: '',
      latitude: '',
      longitude: '',
      isHidden: true,
      isHiddenUser: true,
      isHiddenRestore: true,
      ErrLogin: false,
      profile: {},
      alredyRegistered: false,
      login_err: false,
      error_time: false,
      error_code: false,
      password_err: false,
      unregister: false,
      blocked_err: false,
      blocked_err_store: false,
      error_register: false,
      isPopupVisible: false,
      isFormSubmitted: false,
      otp: Array(4).fill(''),
      inputs: [],
      countdown: 120,
      isCountdownActive: false,
      countdownInterval: null,
      isLoginError: false,
      currentStatus: null,
      status: {
        success: {
          title: 'Введите код',
          description: `Мы отправили СМС с кодом на номер ${this.username}`
        },
        notMoney: {
          title: 'Недостаточно средств',
          description: 'Пополните счет и повторите попытку'
        },
        blocked: {
          title: 'Аккаунт заблокирован',
          description: ''
        },
        error: {
          title: 'Услуга не может быть активировна',
          description: ''
        },
        code_error: {
          title: 'Код неверный',
          description: ''
        }
      }
    }
  },

  computed: {
    countdownText() {
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    },
    showPhoneError() {
      return this.isFormSubmitted && this.phoneError;
    },
    isOtpComplete() {
      return this.otp.every(digit => digit !== '');
    },
    Blocked() {
      this.blocked_err_store = this.$store.getters.blocked

      return this.$store.getters.blocked;
    },
    ErrorLogin() {
      this.login_err = this.$store.getters.error_login
      return this.$store.getters.error_login;
    },
    ErrorPassword() {
      this.password_err = this.$store.getters.error_password
      return this.$store.getters.error_password;
    },

    socket: function () {
      return this.$store.state.socket
    },
    ...mapGetters(['blocked', 'error_login', 'unregistered', 'getProfile', 'MAINUSER']),

  },

  methods: {

    async send_pin() {
      this.error_time = false;
      this.login_err = false;
      let username = this.username.replace(/[^-0-9]/gim, '')
      axios.post('/api/auth/pin', {phone: username}).then((res) => {
        if (res.data.result.payload.code === 0) {
          this.isFormSubmitted = true; // Устанавливаем флаг отправки формы
          this.isPopupVisible = true; // Устанавливаем флаг отправки формы
          this.phoneError = '';
          this.startCountdown();
          this.otp = Array(4).fill('');
        }
        if (res.data.result.code === 425) {
          this.error_time = true;
        } else {
          console.log(res.data.result.code)
          throw new Error(res.data.result.code)
        }
      }).catch((e) => {
        this.login_err = true;
        console.log(e)
      })
    },
    async login() {
      let that = this;

      let username = this.username.replace(/[^-0-9]/gim, '')
      let password = this.otp.join('');

      const lang = 'ru';
      axios.post('/api/check/pin', {phone: username, pin: password}).then((res) => {
        console.log('api/check/pin')
        console.log(res)
        if (res.data.subscribe == 0) {

          try {

            this.$store.dispatch(AUTH_REQUEST, {username, password}).then((resp) => {
              if (!that.socket) {
                that.$store.commit('setsocket', io());
              }
              that.socket.emit('join', resp.result, data => {
                if (typeof data === 'string') {
                }
              });
              that.$store.dispatch('STORE_MAINUSER', resp.result).then(() => {
                that.$store.dispatch('GET_MAINUSER_PREFERENS');
                if (!that.ErrorPassword && !that.ErrorLogin) {
                  that.$router.push({name: 'home'})
                }
              })

            })

          } catch (error) {
            this.phoneError = 'Ошибка сервера';
              console.error('Ошибка при отправке запроса:', error);
          }
        } else if (res.data.result.code == 406) {
          that.error_code = true;
        } else throw new Error(res.data.result.code)
      }).catch((e) => {
        console.log(e)
        that.error_register = true;

      })
    },
    handleOutsideClick(event) {
      const popup = this.$el.querySelector(".otp-container");

      if (popup && !popup.contains(event.target)) {
        this.closePopup();
      }
    },

    startCountdown() {
      this.isCountdownActive = true;
      this.countdown = 120;

      this.countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.isCountdownActive = false;
          clearInterval(this.countdownInterval);
        }
      }, 1000);
    },
    resendCode() {
      if (!this.isCountdownActive) {
        this.startCountdown();
        this.send_pin();

      }
    },
    handleInputPhone() {
      if (this.username.length >= 12) {
        this.phoneError = '';
        this.isFormSubmitted = false;
      }
    },
    handleInput(index, event) {
      let value = event.target.value.replace(/[^0-9]/g, ''); // Убираем все нечисловые символы
      this.otp[index] = value;

      if (value && index < this.otp.length - 1) {
        this.$refs.inputs[index + 1].focus(); // Переключаемся на следующий инпут
      }
    },
    handleDelete(index, event) {
      if (!this.otp[index] && index > 0) {
        this.$refs.inputs[index - 1].focus();
      }
    },

    closePopup() {
      this.isPopupVisible = false;
    }

  },

// не работает на http
  mounted: function () {
//     let that = this
//     if (navigator.geolocation) {
//       var self = this;
//       navigator.geolocation.getCurrentPosition(function (position) {
//         self.position = position.coords;
//
//         self.latitude = position.coords.latitude
//         self.longitude = position.coords.longitude
//       })
//     }
//     document.addEventListener("click", this.handleOutsideClick);
//     // console.log(this.position)
//
      setTimeout(() => {
        document.querySelector('.preloader').style.display = 'none';
      }, 2000)
//
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleOutsideClick);
  },

  //--------------------------------------------------------//
  // __________----------End LOGIN FORM-----------__________//
  //________________________________________________________//

  // this.geoloc();

}
</script>
