<template>
    <div>
        <div class="profile__settings">
            <div class="prof__card mob">
                <div class="card__photo"> <!--v-bind:style="{ 'background-image': 'url(' + userprofile.avatar + ')' }" -->
                    <img
                        v-bind:src="userprofile.avatar || '/assets/images/project/users/placeholder_user.svg'"
                        alt="my-photo"
                        @error="handleImageError"
                    >
                </div>

                <div class="card__name">
                    <span>{{userprofile.name}}</span>
                </div>

                <div class="card__gender">
             <span class="found__gender" v-if="userprofile.gender == 1">
            <img src="/assets/images/project/gender/male.png" alt="gender">
          </span>
                    <span class="gender__img" v-if="userprofile.gender == 0">
            <img src="/assets/images/project/gender/female.png" alt="gender">
          </span>
                    &nbsp;
                    <span>
              <span class="user__age">
                {{userprofile.age}}
              </span> <span class="user__city" v-if="city">
                 ,&nbsp;{{city}}
                </span>
            </span>
                </div>
            </div>
            <div class="user__photos" >
<!--                <div class="default__photo all__photos" v-if="this.Gal.length<1" @click="() => showImg(index)" v-bind:style="{ 'background-image': 'url('+userprofile.avatar + ')' }">-->
<!--                    &lt;!&ndash;<img v-bind:src="userprofile.image_url" alt="my-photo" >&ndash;&gt;-->
<!--                </div>-->
                <div class="all__photos photo__del" v-if="this.Gal.length>0" v-bind:class="{active: item.active}"
                     v-for="(item, index) in Gal"
                     @click="() => showImg(index)"
                     v-bind:index = "item.id"
                     v-bind:style="{ 'background-image': 'url(' + item.img + ')' }"
                >
                    <!--<img v-bind:src="item.img" alt="my-photo" >-->


                </div>
                <div class="all__photos photo__add" v-for="item in UplPlace">




                </div>

            </div>

            <div class="about__profile">
<!--                <div class="prof__card desk">-->
<!--                    <div class="card__photo" v-bind:style="{ 'background-image': 'url(' + userprofile.avatar + ')' }">-->
<!--                        &lt;!&ndash;<img v-bind:src="userprofile.image_url" alt="my-photo">&ndash;&gt;-->
<!--                    </div>-->

<!--                    <div class="card__name">-->
<!--                        <span>{{userprofile.name}}</span>-->
<!--                    </div>-->

<!--                    <div class="card__gender">-->
<!--                      <span class="found__gender" v-if="userprofile.gender == 1">-->
<!--                        <img src="/assets/images/project/gender/male.png" alt="gender">-->
<!--                      </span>-->
<!--                      <span class="gender__img" v-if="userprofile.gender == 0">-->
<!--                        <img src="/assets/images/project/gender/female.png" alt="gender">-->
<!--                      </span>-->
<!--                      &nbsp;-->
<!--                      <span>-->
<!--                        <span class="user__age">-->
<!--                          {{userprofile.age}}-->
<!--                        </span>-->
<!--                        <span class="user__city" v-if="city">-->
<!--                          ,&nbsp;{{city}}-->
<!--                        </span>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="profile__me" v-if="userprofile.about">
                    <p class=about__head>Обо мне</p>

                    <div class="about__me">
                        {{userprofile.about}}
                    </div>
                </div>

              <div class="profile__me" v-if="userprofile.work">
                <p class=about__head>Чем занимаюсь</p>

                <div class="about__me">
                  {{userprofile.work}}
                </div>
              </div>

                <div class="profile__hobbies" v-if="InterestsUser.length>0">
                    <p class=about__head>Интересы</p>

                    <div class="about__content">
                        <div class="hobby__item"
                             v-for="(item, index) in InterestsUser"> <span>{{item}}</span></div>

                    </div>
                </div>

            </div>
        </div>



    </div>
</template>


<script>
    import axios from 'axios';
    // import r from '@/js/route';
    // import VueCarousel from '@chenfengyuan/vue-carousel';
    export default {

        // components: { VueCarousel },
        data () {
            return {
                userprofile : [],
                Gal:[],
                UplPlace:[],
                GalPopup:[],
                InterestsUser:[],
                Carus:[],
                visible: false,
                city:'',

            }
        },
      props: ['nonce'],

        methods: {
            showImg (index) {

                this.index = index+1
                console.log(index)

                this.visible = true
            },
            handleHide () {
                this.visible = false
            },
            getUser () {
                console.log('test user '+this.$route.params.id)

                    let user_id = this.$route.params.id
                // console.log('test user'+this.id)
                // console.log('test user'+r("api.user.ById" ,[`${this.id}`]));
                axios.post("/api/user/getById",{id:user_id})

                    .then(async(response) => {
                        console.log(response.data);
                        let that=this
                        this.userprofile = response.data.result;
                      response.data.result.gallery.forEach((elem)=>{
                        this.Gal.push({active:false,id:elem.id,img:elem.url})
                        this.GalPopup.push(elem.url)
                      })
                        await axios.post('/api/user/hobbies/get',{id:user_id}).then((response)=>{response.data.result.forEach((elem)=> that.InterestsUser.push(elem.name))})
                    })
                    .catch(error => {
                        console.log('ошибка ' + error);
                    });
            },
            handleImageError(event) {
              event.target.src = '/assets/images/project/users/placeholder_user.svg'
            },
        },
        mounted() {
            this.getUser ();
        }
    }
</script>
<style :nonce="nonce">
    /*.userprofile img{max-width:50%}*/
    .carusel-slide {
        align-items: center;
        background-color: #666;
        color: #999;
        display: flex;
        font-size: 1.5rem;
        justify-content: center;
        min-height: 10rem;
    }
    .carousel__indicators{top:0}
    @media (min-width:1280px) {
        .config__title {
            display: none
        }
    }
</style>


