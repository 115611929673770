<template>
    <div>
        <!-- <div class="page__name">
            <i class="icon-radar"></i><span>ПОИСК</span>
        </div> -->
        <!-- <div class="content__logo">
            <img src="/assets/images/project/Logo.svg" alt="Знакомства">
            <i class="all_icon icon-question-circle"></i>
        </div> -->
<div v-if="MainUser">
        <div id="UserPack" class="cards__actions"  v-bind:class="{hidden: this.$store.getters.NOUSER, hidden:checkCount}">
            <UserPack
                    :users="usersList"
                    @cardAccepted="handleCardAccepted"
                    @cardRejected="handleCardRejected"
                    @cardSkipped="handleCardSkipped"
                    @hideCard="removeCardFromDeck"
            />
            <div class="found__actions" v-bind:class="NOUSERS_check_actions">

                <div class="red__shadow"></div>
                <div class="green__shadow"></div>

                <div class="found__remove" v-on:click="handleCardRejected(); removeCardFromDeck()">
                    <img src="@/assets/images/project/reject-icon.png" alt="dislike">
                </div>
                <div class="found__like" v-on:click="handleCardAccepted(); removeCardFromDeck()">
                  <img src="@/assets/images/project/accept-icon.png" alt="like">
                </div>
            </div>
        </div>


        <div class="no__results" v-bind:class="{visible: noMoney}">

            <div class="pulse__img">
                <span class="call__pulse"></span>
                <div class="card__photo" v-if="MainUser.avatar">
                    <img v-bind:src="MainUser.avatar" alt="my-photo">
                </div>
            </div>

            <div class="result__phraze">
                Для активации полного функционала, пожалуйста, пополните баланс
            </div>

        </div>
    <div class="no__results" v-bind:class="{visible: notActiveText}">

        <div class="pulse__img">
            <span class="call__pulse"></span>
            <div class="card__photo" v-if="MainUser.avatar">
                <img v-bind:src="MainUser.avatar" alt="my-photo">
            </div>
        </div>

        <div class="result__phraze">
            Для активации полного функционала, пожалуйста, оформите подписку
        </div>

    </div>
        <div class="no__results" v-bind:class="NOUSERS_check">

            <div class="pulse__img">
                <span class="call__pulse"></span>
                <div class="card__photo">
                    <img
                        v-bind:src="MainUser.avatar || '/assets/images/project/users/placeholder_user.svg'"
                        alt="my-photo"
                        @error="handleImageError"
                    >
                </div>
            </div>

            <div class="result__phraze">
              Анкеты закончились. Попробуйте изменить фильтры или зайдите позже.
            </div>
            <router-link to="/settings">
                <div class="to__settings">
                    <span>Изменить</span>
                </div>
            </router-link>
        </div>
        <div class="pop__bingo" v-bind:class="{active: match_active}" v-if="Matched_user!=''">
            <div class="bingo__content">
                <img src="/assets/images/project/main__logo.png" alt="SeZim-Знакомства">

                <div class="bingo__text">
                    Пара совпала!
                </div>

                <div class="bingo__subtext">
                    Вы и <span class="capit">{{Matched_user.name}}</span> лайкнули друг друга
                </div>
                <div class="bingo__photos">
                    <div class="bingo__user" v-if="MainUser.avatar">
                        <img v-bind:src="MainUser.avatar" alt="my-photo">
                    </div>

                    <div class="bingo__user" v-if="Matched_user.avatar">
                        <img v-bind:src="Matched_user.avatar" alt="my-photo">
                    </div>
                </div>

                <div class="bingo__buttons">
                    <div class="bingo__btn message__btn" v-on:click="storeMatch(Matched_user.id)">
                        <router-link
                                tag="a"
                                class="button"
                                :to="{name: 'matches'}"
                        >
                            <span>Посмотреть совпадение</span></router-link>
                    </div>

                    <div class="bingo__btn cancel__btn" v-on:click="match_active = false">
                        <span>Не сейчас</span>
                    </div>
                </div>
            </div>
        </div>
<!--        <div class="pop__bingo" v-bind:class="{active: notActive}">-->
<!--            <div class="bingo__content">-->
<!--&lt;!&ndash;                <img src="/images/project/main__logo.png" alt="SeZim-Знакомства">&ndash;&gt;-->

<!--                <div class="bingo__subtext">-->
<!--                    Вы не подписаны!-->
<!--                </div>-->

<!--                <div class="bingo__text ">-->

<!--                    Получите-->
<!--                    безлимит-->
<!--                    лайков-->



<!--                </div>-->
<!--                <div class="bingo__subtext">-->
<!--                Вы сможете видеть кому вы понравились,-->
<!--                отправлять новым собеседникам сообщения-->
<!--                и многое другое!. Подробности в Правилах-->
<!--                </div>-->


<!--                <div class="bingo__buttons">-->
<!--                    <div class="bingo__btn message__btn" v-on:click="Subscribe" v-bind:class="{hidden:subscribeSucsess}">-->

<!--                            <span>Подписаться</span>-->
<!--                    </div>-->

<!--                    <div class="bingo__text error_subscribe" v-if="subscribeError">-->
<!--                        Произошла ошибка, попробуйте позже-->
<!--                    </div>-->
<!--                    <div class="bingo__btn cancel__btn" v-on:click="notActiveText = true; notActive = false">-->
<!--                        <span>Не сейчас</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="bingo__subtext">-->
<!--                    Стоимость подписки 30тг/сутки-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
</div>
    </div>
</template>

<script>

    import UserPack from "../user_pack/index.vue";
    import axios from 'axios';

    export default {
      props: ['nonce'],
        data() {
            return {
                ListUser: [],
                Matched_user: [],
                match_active: false,
                count:'',
                checkCount:false,
                notActive:false,
                notActiveText:false,
                noMoney:false,

                subscribeError:false,
                subscribeSucsess:false
            }
        },

        components: {
            UserPack
        },
beforeMount() {
  this.$store.dispatch('GET_USERS',this.$store.getters.MAINUSER)
  this.$store.dispatch('GET_MATCHES')
},
      computed: {
            MainUser() {
                  return this.$store.getters.MAINUSER;
            },
            usersList() {
                return this.$store.getters.USERS;
            },
            NOUSERS_check(){
                return {
                    visible: this.$store.getters.NOUSERS && !this.notActive&&!this.noMoney,
                }
            },
            NOUSERS_check_actions() {
                return {
                    hidden: this.$store.getters.NOUSERS || this.notActive || this.noMoney,
                }
            },
        },

        methods: {
            storeMatch(id) {
                console.log(id);
                // localStorage.setItem('id_to', id);
                // axios.post(r("api.user.matches.update"), {
                //     id: id,
                //
                // })
                //     .then(
                //         this.$store.dispatch('GET_NEW_MATCHES')
                //     )
            },

            handleCardAccepted() {
                console.log("handleCardAccepted");

                let card = document.querySelector('.isCurrent')
                let cardChild = card.querySelector('.card_child')
                let cardId = cardChild.getAttribute('data-id')


                // console.log('test user'+r("api.swipe", [`${cardId}`]));
                axios.post("/api/swipe",  {action: 'like',to:cardId,from:this.MainUser.id})

                    .then(resp => {

                      console.log(resp)

                        if (resp.data.result.user) {
                          console.log("/api/swipe Matched_user")
                          console.log(this.Matched_user)
                            this.Matched_user = resp.data.result.user[0]
                            this.$store.dispatch('GET_MATCHES')

                            this.match_active = true

                        }

                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            handleCardRejected() {
                console.log("handleCardRejected");
              let card = document.querySelector('.isCurrent')
              let cardChild = card.querySelector('.card_child')
              let cardId = cardChild.getAttribute('data-id')
              axios.post("/api/swipe",  {action: 'dislike',to:cardId,from:this.MainUser.id})

                  .then(resp => {

                    console.log(resp)


                    })
                    .catch(err => {
                        console.log(err);
                    })
            },
            handleCardSkipped() {
                console.log("handleCardSkipped");
            },
            handleImageError(event) {
              event.target.src = '/assets/images/project/users/placeholder_user.svg'
            },
            removeCardFromDeck() {
                this.$store.dispatch('REMOVE_OBJECT_FROM_USERS');
                        }
        }
    };
</script>


<style :nonce="nonce">

.cards__actions.hidden {
  display: none
}

.no__results.visible {
  display: flex !important;
}

.no__results {
  display: none !important;
}
#Main .cards__actions {
  width: 360px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  max-height: 400px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
}

#Main .cards__actions .found__user {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 9;
  border-radius: 10px;
}

#Main .cards__actions .found__user::after {
  content: '';
  width: 266px;
  /* background-color: white; */
  position: absolute;
  border-radius: 10px;
  bottom: -109px;
  z-index: 3;
}

#Main .cards__actions .found__user .all__photos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  width: 258px;
  height: 100%;
  min-height: 398px;
  border-radius: 10px;
  z-index: 9;
  max-height: 400px;
}

#Main .cards__actions .found__user .all__photos img {
  width: 100%;
  height: 100%;
  max-height: 400px;
  z-index: 8;
}

#Main .cards__actions .found__user .default__avatar {
  position: absolute;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 666px;
  width: 90px;
  height: 90px;
  bottom: -35px;
  z-index: 9;
  border: 1px solid #ffffff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5);
}

#Main .cards__actions .found__user .default__avatar img {
  width: 100%;
}

#Main .cards__actions .found__user .found__data {
  width: 180px;
  height: 60px;
  position: absolute;
  bottom: -105px;
  color: #13152d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 9;
  text-align: center;
}

#Main .cards__actions .found__user .found__data .some__name {
  font-size: 17px;
  font-weight: 400;
}

#Main .cards__actions .found__user .found__data .some__detail {
  font-size: 11px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#Main .cards__actions .found__user .found__data .some__detail .found__gender {
  margin-right: 8px;
}

#Main .cards__actions .found__user .found__data .some__detail .found__gender img {
  width: 14px;
  height: 14px;
}

#Main .cards__actions .found__actions {
  position: absolute;
  z-index: 9;
  bottom: -55px;
  height: 78px;
  width: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

#Main .cards__actions .found__actions .found__remove,#UserPack.cards__actions .found__actions .found__like {
  width: 78px;
  height: 78px;
  border-radius: 666px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 35px;
  font-weight: 400;
  z-index: 9;
  background: #ffffff;
}

#UserPack.cards__actions .red__shadow,#UserPack.cards__actions .green__shadow {
  position: absolute;
  width: 140px;
  height: 78px;
  opacity: 0.35;
  z-index: 5;
}

#UserPack.cards__actions .red__shadow {
  background-image: none;
  left: -90px;
}

#UserPack.cards__actions .green__shadow {
  background-image: none;
  right: -90px;
}
#UserPack.cards__actions .found__user::after {
  height: 515px;
  bottom: -115px;
}
@media screen and (min-width: 1280px) {
 #UserPack.cards__actions .found__user .found__data .some__name {
    width: 250px;
  }
 #UserPack.cards__actions .found__user .default__avatar {
    width: 105px;
    height: 105px;
  }
 #UserPack.cards__actions {
    width: 444px;
    height: 100%;
    max-height: 400px;
  }
 #UserPack.cards__actions .found__user::before {
    width: 290px;
  }
 #UserPack.cards__actions .found__user::after {
    width: 310px;
  }
 #UserPack.cards__actions .found__user .all__photos {
    width: 304px;
    height: 100%;
    min-height: 466px;
    max-height: 472px;
  }
 #UserPack.cards__actions .found__user .all__photos img {
    max-height: 472px;
  }
 #UserPack.cards__actions .found__user::after {
    height: 580px;
    bottom: -110px;
  }
  .subs__couple .photo__wrapper {
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset;
  }
}

@media screen and (min-width: 1920px) {
  #Main .page__name {
    font-size: 36px;
  }
 #UserPack.cards__actions .found__user .default__avatar {
    width: 150px;
    height: 150px;
  }
 #UserPack.cards__actions {
    width: 570px;
    height: 100%;
    /*max-height: 675px;*/
  }
 #UserPack.cards__actions .found__user::before {
    width: 434px;
  }
 #UserPack.cards__actions .found__user .all__photos {
    width: 434px;
    height: 675px;
    min-height: 675px;
    max-height: 675px;
  }
 #UserPack.cards__actions .found__user .all__photos img {
    width: 100%;
  }
 #UserPack.cards__actions .found__actions .found__remove,#UserPack.cards__actions .found__actions .found__like {
    width: 110px;
    height: 110px;
    font-size: 50px;
    z-index: 9;
  }
 #UserPack.cards__actions .found__actions {
    height: 110px;
    bottom: -212px;
  }
 #UserPack.cards__actions .red__shadow,#UserPack.cards__actions .green__shadow {
    height: 110px;
  }
 #UserPack.cards__actions .found__user .found__data .some__name {
    font-size: 24px;
    width: 405px;
  }
 #UserPack.cards__actions .found__user .found__data {
    width: 405px;
    bottom: -105px;
  }
 #UserPack.cards__actions .found__user .found__data .some__detail {
    font-size: 16px;
  }
 #UserPack.cards__actions .found__user .found__data .some__detail .found__gender img {
    width: 20px;
    height: 20px;
  }
  #Main .my__card .menu__item {
    font-size: 24px;
    margin-bottom: 20px;
  }
  #Main .my__card {
    width: 265px;
    margin-left: 15px;
  }
  .menu__item {
    width: 100% !important;
  }
  #Main .my__card .other__actions {
    width: 100%;
  }
  .under, .logout {
    font-size: 18px !important;
  }
  #Main .my__card .logout {
    font-size: 24px !important;
  }
  #Main .my__card .card__photo {
    width: 148px;
    height: 148px;
  }
  #Main .my__card .in__search {
    margin-bottom: 24px;
  }
  #Main .my__card .other__actions {
    margin-top: 40px;
  }
  #Main .my__card .account__actions .my__stats:after {
    bottom: -20px;
    left: -5px;
  }
}

</style>