
import user from './modules/user'
import auth from './modules/auth'
import axios from 'axios';
// import router from '/routes';
import { createStore } from 'vuex'
import {io} from "socket.io-client";

const debug = process.env.NODE_ENV !== 'production'


const storeObj = {
    modules: {
      user,
      auth,
    },

  state: {
    socket: null,
    socketinit: null,
    users: null,
    new_likes:'',
    new_matches:'',
    all_new_messages:'',
    mainuser: null,
    mainuser_prefs: null,
    mainuser_hobbies: null,
    nousers:true,
    matches: [],
    newuser:false,
    messages:[],
  },



  getters: {
      SOCKET: state => {
        return state.socket;
      },
    socketinit: state => {
        return state.socketinit;
      },
    NEW_LIKES: state => {
      return state.new_likes;
    },
    NEW_MATCHES: state => {
      return state.new_matches;
    },
    ALL_NEW_MESSAGES: state => {
      return state.all_new_messages;
    },
    USERS: state => {
      return state.users;
    },
    NOUSERS: state => {
      return state.nousers;
    },
    MAINUSER: state => {
      return state.mainuser;
    },
    MAINUSER_HOBBIES: state => {
      return state.mainuser_hobbies;
    },
    NEWUSER: state => {
      return state.newuser;
    },
    MATCHES: state => {
      return state.matches;
    },
    MESSAGES: state => {
      return state.messages;
    },
  },

  actions: {

    ADD_MESSAGE: async (context, payload) => {
      context.state.messages.push(payload)
      console.log(context.state)
    },
      SET_SOCKET: async (context, payload) => {
        context.commit('setsocket', io())
        console.log(context.state)
      },
    GET_USERS: async (context, payload) => {
      let {data} = await axios.post('/api/searchUsers',{user:payload})
      context.commit('SET_USERS', data.result)
      if (data.result.length==0)context.commit('NO_USERS');
      else context.commit('FIND_USERS');
      // console.log('search complete');
    },
    GET_NEW_LIKES: async (context, payload) => {
      // let {data} = await axios.get(r("api.user.likes.new"))
      //
      // let count=0
      // for (let i = 0; i < data.length; i++) {
      //   if (data[i].new == 'Y') {
      //     count+=1
      //   }
      // }
      // context.commit('SET_NEW_LIKES', count);
    },
    GET_NEW_MATCHES: async (context, payload) => {
      // let {data} = await axios.get(r("api.user.matches.new"))
      // let count=0
      // for (let i = 0; i < data.length; i++) {
      //   if (data[i].new == 'Y') {
      //     count+=1
      //   }
      // }
      //
      //
      // context.commit('SET_NEW_MATCHES', count);
    },
    GET_ALL_NEW_MESSAGES: async (context, payload) => {
      // let {data} = await axios.get(r("api.user.messages.new.all"))
      //
      // context.commit('SET_ALL_NEW_MESSAGES', data[0].messages_count);
    },
    REMOVE_OBJECT_FROM_USERS: async (context, payload) => {
      context.commit('REMOVE_OBJECT_FROM_USERS');
    },
    GET_MAINUSER: async (context) => {
      if(!localStorage.getItem('user-token')) throw 'no user-token';
      let {data} = await axios.post("/api/user/get",{token:localStorage.getItem('user-token')})
      if (data.error){throw 'no user';}
      context.commit('SET_MAINUSER', data.result);
      await context.dispatch('GET_MAINUSER_PREFERENS');
      await context.dispatch('GET_MATCHES');
    },
    GET_MAINUSER_BY_ID: async (context,payload) => {
        let id
        if(!payload){
          if(!localStorage.getItem('id')) throw 'no id';
          else id=localStorage.getItem('id')
        }
          else {
          id = payload
        }
      let {data} = await axios.post("/api/user/getById",{id:id})

      await context.commit('SET_MAINUSER', data.result);
      await context.dispatch('GET_MAINUSER_PREFERENS');
      await context.dispatch('GET_MATCHES');
    },
    GET_MAINUSER_PREFERENS: async (context) => {
      let {data} = await axios.post("/api/user/preference/get",{id:context.state.mainuser.id})
      context.commit('SET_MAINUSER_PREFERENS', data.result);
      await context.dispatch('GET_MAINUSER_HOBBIES');
    },
    GET_MAINUSER_HOBBIES: async (context) => {
      let {data} = await axios.post("/api/user/hobbies/get",{id:context.state.mainuser.id})
      console.log('prefs data')
      console.log(data)

      context.commit('SET_MAINUSER_HOBBIES', data.result);
    },
    STORE_MAINUSER: async (context, payload) => {
      context.commit('SET_MAINUSER', payload);
    },
    GET_MATCHES: async (context, payload) => {
      let {data} = await axios.post("/api/user/matches/get",{id:context.state.mainuser.id})
      context.commit('SET_MATCHES', data.result);
      const rooms=[]
      data.result.forEach((elem)=>{
        rooms.push(elem.room_id)
      })
      context.commit('SET_MAINUSER_ROOMS', rooms);
    },



    CLEAR_USERS: async (context, payload) => {
      context.commit('CLEAR_USERS', null);
    },
    SET_NEW_USER: async (context, payload) => {
      context.commit('SET_NEW_USER', payload);
    },
    UPD_NEW_USER: async (context, payload) => {
      context.commit('UPD_NEW_USER', payload);
    },

  },
  mutations: {
    setsocket: (state, data) => {
      state.socket = data;
    },
    setsocketinit: (state, data) => {
      state.socketinit = data;
    },
    SET_USERS: (state, payload) => {
      state.users = payload;
    },
    SET_NEW_LIKES: (state, payload) => {
      state.new_likes = payload;
    },
    SET_NEW_MATCHES: (state, payload) => {
      state.new_matches = payload;
    },
    SET_ALL_NEW_MESSAGES: (state, payload) => {
      state.all_new_messages = payload;
    },

    SET_NEW_USER: (state, payload) => {
      state.newuser = true;
    },
    UPD_NEW_USER: (state, payload) => {
      state.newuser = false;
    },

    REMOVE_OBJECT_FROM_USERS: (state, payload) => {
         state.users.splice(0, 1);
      if (state.users.length==0) state.nousers=true
    },

    ADD_USERS: (state, payload) => {
      state.users.push(payload);
    },
    NO_USERS: (state, payload) => {
      state.nousers=true
    },
      FIND_USERS: (state, payload) => {
          state.nousers=false
      },
    CLEAR_USERS: (state, payload) => {
      state.users = null;
    },
    SET_MAINUSER: (state, payload) => {
      state.mainuser = payload;
    },
    SET_MAINUSER_PREFERENS: (state, payload) => {
      state.mainuser.prefs = payload;
    },
    SET_MAINUSER_HOBBIES: (state, payload) => {
      state.mainuser_hobbies = payload;
    },
    SET_MATCHES: (state, data) => {
      state.matches = data;
    },
    SET_MAINUSER_ROOM: (state, data) => {
      state.mainuser.room_id = data;
    },
    SET_MAINUSER_ROOMS: (state, data) => {
      state.mainuser.rooms = data;
    },
  },
}
let store=new createStore(storeObj);

export default {
  store
}
